import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';

export const selectUserDomain = (state: ApplicationState) =>
  state.user || initialState;

export const makeSelectUser = () =>
  createSelector(selectUserDomain, (state) => state.user);

export const makeSelectUserLoading = () =>
  createSelector(selectUserDomain, (state) => state.loading);

export const makeSelectUserName = () =>
  createSelector(makeSelectUser(), (user) => user.name);

export const makeSelectUserId = () =>
  createSelector(makeSelectUser(), (user) => user.id);

export const makeSelectReferralCode = () =>
  createSelector(makeSelectUser(), (user) => user.referralCode);

export const makeSelectEmail = () =>
  createSelector(makeSelectUser(), (user) => user.email);

export const makeSelectPhone = () =>
  createSelector(makeSelectUser(), (user) => user.phone);

export const makeSelectAvatar = () =>
  createSelector(makeSelectUser(), (user) => user.avatar);

// first login user
export const makeSelectIsPromotionUser = () =>
  createSelector(makeSelectUser(), (user) => {
    if (!user || user.loginCounts === undefined) {
      return false;
    }
    return user.loginCounts <= 1; // 처음 로그인 시 true
  });
export const makeSelect2FA = () =>
  createSelector(makeSelectUser(), (user) => user.isEnableGoogleAuthenticator);

export const makeSelectEmailNotifications = () =>
  createSelector(selectUserDomain, (state) => state.notificationSettings);

export const makeSelectPhoneIsVerified = () =>
  createSelector(makeSelectUser(), (user) => !!(user.phone && user.isVerified));

export const makeSelectUserIsVerified = () =>
  createSelector(
    makeSelectUser(),
    (user) => user.documentsVerifiedStatus === 'VERIFIED'
  );

export const makeSelectDocumentsVerifiedStatus = () =>
  createSelector(makeSelectUser(), (user) => user.documentsVerifiedStatus);

export const makeSelectUserCurrency = () =>
  createSelector(makeSelectUser(), (user) => user.settings.currency);

export const makeSelectUserFromUSA = () =>
  createSelector(makeSelectUser(), (user) => user.country === 'US');
