import React, { useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectSubscriptionKRW } from '../../../ModalProvider/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import krwIcon from '../../../../assets/icons/krwIcon.png';

import s from './SubscriptionKRWModal.module.less';
import {
  makeSelectEmail,
  makeSelectUserId,
  makeSelectUserName,
} from '../../../User/selectors';
import { makeSelectFunds2 } from '../../../Funds/selectors';

const SubscriptionKRWModal = () => {
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(makeSelectSubscriptionKRW());
  const userId = useAppSelector(makeSelectUserId());
  const userName = useAppSelector(makeSelectUserName());
  const userEmail = useAppSelector(makeSelectEmail());
  const [message, setMessage] = useState('');

  const onClose = () => {
    dispatch(
      modalActions.open({
        name: 'subscriptionKRW',
        data: { visible: false },
      })
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className={s.wrapper}
      closable={false}
      destroyOnClose
    >
      <div className={cx(s.container, s.light)}>
        <div style={{ width: '100%', height: '1px' }} />
        <form
          className={s.content}
          target="_blank"
          action="https://formsubmit.co/tech@vegaxholdings.com"
          method="POST"
        >
          <input
            readOnly
            className={s.invisible}
            type="text"
            name="User Id"
            value={userId}
          />
          <input
            readOnly
            className={s.invisible}
            type="text"
            name="User Name"
            value={userName}
          />
          <input
            readOnly
            className={s.invisible}
            type="text"
            name="User Email"
            value={userEmail}
          />
          <input
            readOnly
            className={s.invisible}
            type="text"
            name="Symbol"
            value="KRW"
          />
          <input
            readOnly
            className={s.invisible}
            type="text"
            name="Message"
            value={message}
          />
          <div className={s.headerContainer}>
            <span className={s.header}>Subscription Request</span>
          </div>
          <div className={s.info_box}>
            {`Participation in VegaX crypto strategies using Korean Won is only available via OTC.

Please submit your question here or send us an inquiries to: \n\n`}
            <strong>clients@vegaxholdings.com</strong>
          </div>
          <div className={s.vstack}>
            <div className={s.label}>Strategy</div>
            <div
              style={{
                display: 'flex',
                padding: '8px 16px',
                gap: '16px',
                border: '1px solid #B5CFE6',
                borderRadius: '200px',
              }}
            >
              <img
                src={krwIcon}
                alt="krw"
                style={{ width: '24px', height: '24px' }}
              />
              <span style={{ fontSize: '14px', fontWeight: 600 }}>
                Korean Won
              </span>
            </div>
          </div>
          <div className={s.vstack}>
            <div className={s.label}>Message</div>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Button
            size="large"
            className={s.submit_button}
            htmlType="submit"
            shape="round"
            block
          >
            Submit
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default SubscriptionKRWModal;
