import React, { useState } from 'react';
import cx from 'classnames';
import BuyProduct from './BuyProduct';
import SellProduct from './SellProduct';
import { useAppSelector } from '../../../../redux/store';
import { makeSelectProducts } from '../../selectors';
import { makeSelectFunds2 } from '../../../Funds/selectors';
import { makeSelectPortfolioList } from '../../../Portfolio/selectors';
import { makeSelectCurrencyPrice } from '../../../Currencies/selectors';
import { makeSelectUserCurrency } from '../../../User/selectors';
import { ProductOrderType } from '../../types';

import s from './TradeProducts.module.less';

type Props = {
  theme?: 'light' | 'dark';
  orderType?: ProductOrderType;
  productSymbol?: string;
  isCurrentProduct?: boolean;
  noSell?: boolean;
};

const TradeProducts = ({
  theme = 'light',
  orderType = 'Buy',
  productSymbol,
  isCurrentProduct = false,
  noSell = false,
}: Props) => {
  const products = useAppSelector(makeSelectProducts());
  const funds = useAppSelector(makeSelectFunds2());
  const priceBtc = useAppSelector(makeSelectCurrencyPrice('BTC'));
  const myProducts = useAppSelector(makeSelectPortfolioList());
  const userCurrency = useAppSelector(makeSelectUserCurrency());

  const [buyOrSell, setBuyOrSell] = useState('buy');

  return (
    <div
      className={cx(
        s.container,
        s[theme],
        isCurrentProduct && s.current_product
      )}
    >
      <div style={{ width: '100%', height: '1px' }} />
      <div className={s.content}>
        <input
          readOnly
          className={s.invisible}
          type="text"
          name="Buy or Sell"
          value={buyOrSell}
        />
        <div className={s.headerContainer}>
          <span className={s.header}>Investment Request</span>
        </div>
        <div className={s.toggle}>
          <button
            type="button"
            onClick={() => setBuyOrSell('buy')}
            className={
              buyOrSell === 'buy' ? s.toggle_item_active : s.toggle_item
            }
          >
            Buy
          </button>
          <button
            type="button"
            onClick={() => setBuyOrSell('sell')}
            className={
              buyOrSell === 'sell' ? s.toggle_item_active : s.toggle_item
            }
          >
            Sell
          </button>
        </div>
        {buyOrSell === 'buy' ? (
          <BuyProduct
            theme={theme}
            isCurrentProduct={isCurrentProduct}
            productSymbol={productSymbol}
            funds={funds}
            products={products}
            priceBtc={priceBtc}
            fiatCurrency={userCurrency}
          />
        ) : (
          <SellProduct
            theme={theme}
            isCurrentProduct={isCurrentProduct}
            productSymbol={productSymbol}
            funds={funds}
            products={myProducts}
            priceBtc={priceBtc}
            fiatCurrency={userCurrency}
          />
        )}
      </div>
    </div>
  );
};

export default TradeProducts;
