// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.Layout-module__app_content--2hLiq {\n  padding: 105px 5px 20px;\n}\n@media (min-width: 321px) {\n  .Layout-module__app_content--2hLiq {\n    padding: 105px 10px 20px;\n  }\n}\n@media (min-width: 1200px) {\n  .Layout-module__app_content--2hLiq {\n    padding: 30px 20px;\n  }\n}\n.ant-layout.Layout-module__app--gaoYz {\n  min-height: 100vh;\n}\n", "",{"version":3,"sources":["webpack://src/components/Layout/Layout.module.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAA3F;EACE,uBAAA;AAEJ;AAAI;EAAA;IACE,wBAAA;EAGJ;AACF;AAFI;EAAA;IACE,kBAAA;EAKJ;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":["@import \"../../theme/variables\";\n\n.app {\n  &_content {\n    padding: 105px 5px 20px;\n\n    @media @xs {\n      padding: 105px 10px 20px;\n    }\n    @media @xl {\n      padding: 30px 20px;\n    }\n  }\n}\n\n:global(.ant-layout) {\n  &.app {\n    min-height: 100vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app_content": "Layout-module__app_content--2hLiq",
	"app": "Layout-module__app--gaoYz"
};
export default ___CSS_LOADER_EXPORT___;
