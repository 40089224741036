import { useState, useEffect } from 'react';

const useMedia = (query: string, defaultState = false) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) return;
      setState(Boolean(mql.matches));
    };

    mql.addListener(onChange);
    setState(Boolean(mql.matches));

    return () => {
      mounted = false;

      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
};

export default useMedia;
