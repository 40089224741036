import React, { useEffect, useState } from 'react';
import { Button, Input, Select, message } from 'antd';
import { formatNumber } from '../../../../utils/format';
import { isVusdcOrVdarc } from '../../../../utils/validate';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectProductsLogo } from '../../selectors';
import { PortfolioProduct } from '../../../Portfolio/types';
import { FundType } from '../../../Funds/types';
import {
  makeSelectUserId,
  makeSelectUserName,
  makeSelectEmail,
} from '../../../User/selectors';

import s from './TradeProducts.module.less';
import { FiatCurrencies } from '../../../Currencies/types';
import { modalActions } from '../../../ModalProvider/slice';
import mainApiProtected from '../../../../services/mainApiProtected';

const { Option } = Select;

type Props = {
  theme: 'light' | 'dark';
  productSymbol?: string;
  isCurrentProduct?: boolean;
  funds: FundType[];
  products: PortfolioProduct[];
  priceBtc: number;
  fiatCurrency: FiatCurrencies;
};

const SellProduct = ({
  isCurrentProduct,
  theme,
  productSymbol,
  funds,
  products,
  priceBtc,
  fiatCurrency,
}: Props) => {
  const dispatch = useAppDispatch();
  const productsLogo = useAppSelector(makeSelectProductsLogo());
  const [depositTo, setDepositTo] = useState('BTC');
  const [currentFund, setCurrentFund] = useState<FundType>(funds[0]);
  const [currentProduct, setCurrentProduct] = useState<
    PortfolioProduct | undefined
  >(undefined);
  // const trading = useAppSelector(
  //   makeSelectStrategyIsTrading(currentProduct?.symbol || '', 'Sell')
  // );
  const [amount, setAmount] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [amountInFiat, setAmountInFiat] = useState(0);
  const [percentageOfBalance, setPercentageOfBalance] = useState<number | null>(
    null
  );

  const userId = useAppSelector(makeSelectUserId());
  const userName = useAppSelector(makeSelectUserName());
  const userEmail = useAppSelector(makeSelectEmail());

  useEffect(() => {
    if (productSymbol) {
      setCurrentProduct(products.find((p) => p.symbol === productSymbol));
    } else {
      setCurrentProduct(products[0]);
    }
  }, [products, productSymbol]);

  useEffect(() => {
    if (percentageOfBalance && currentProduct) {
      const productAmount =
        currentProduct.symbol === 'VUSDC'
          ? currentProduct.amountWithProfit
          : currentProduct.amount;
      setAmount(((productAmount * percentageOfBalance) / 100).toFixed());
    }
  }, [currentProduct, percentageOfBalance]);

  useEffect(() => {
    if (currentProduct) {
      setAmountInFiat(
        Math.floor(parseFloat(amount || '0')) * currentProduct.valueInCurrency
      );
    }
  }, [amount, currentProduct]);

  useEffect(() => {
    setEstimatedAmount(
      parseFloat((amountInFiat / currentFund.price).toFixed(8))
    );
  }, [amountInFiat, currentFund]);

  useEffect(() => {
    if (currentProduct) {
      const fund = funds.find((f) => f.symbol === currentProduct.baseCurrency);
      if (fund) {
        setDepositTo(fund.symbol);
      } else {
        setDepositTo(funds[0].symbol);
      }
    }
  }, [currentProduct, funds]);

  useEffect(() => {
    const searchFund =
      depositTo === 'VUSDC' ? currentProduct?.baseCurrency : depositTo;
    const fund = funds.find((f) => f.symbol === searchFund);
    setCurrentFund(fund || funds[0]);
  }, [currentProduct?.baseCurrency, depositTo, funds]);

  const disabled =
    !amount ||
    !currentProduct ||
    parseFloat(amount) >
      (currentProduct.symbol === 'VUSDC'
        ? currentProduct.amountWithProfit
        : currentProduct.amount);

  const handleClick = async () => {
    try {
      await mainApiProtected.orderSell({
        user_id: userId,
        product_name: currentProduct?.symbol ?? '',
        quantity: +amount,
        currency: currentFund.symbol,
      });

      dispatch(modalActions.closeAll());
      dispatch(
        modalActions.open({
          name: 'confirmation',
          data: {
            visible: true,
            type: 'SELL',
            reqSummary: {
              productSymbol: currentProduct?.symbol || '',
              currencySymbol: currentFund.icon,
              amount: +amount,
            },
          },
        })
      );
    } catch (e) {
      message.error(e);
    }
  };

  return (
    <>
      {!isCurrentProduct && (
        <div className={s.label}>
          <span>Sell</span>
          <Select
            size="large"
            className={s.select}
            value={currentProduct?.symbol}
            // @ts-ignore
            onChange={(value, { product }: { product: PortfolioProduct }) =>
              setCurrentProduct(product)
            }
          >
            {products.map((product) => (
              <Option
                key={product.symbol}
                value={product.symbol}
                product={product}
              >
                <div className={s.select_item}>
                  <img
                    src={productsLogo[product.symbol]}
                    alt={product.symbol}
                  />
                  <span>
                    {formatNumber(
                      product.symbol === 'VUSDC'
                        ? product.amountWithProfit
                        : product.amount
                    )}{' '}
                    {product.symbol}
                  </span>
                </div>
              </Option>
            ))}
          </Select>
          <Input
            size="large"
            className={s.input}
            placeholder="Enter amount"
            addonAfter={currentProduct?.symbol ?? 'Select'}
            bordered={true}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
      )}

      <div className={s.label}>
        <span>Deposit to</span>
        <Select
          size="large"
          className={s.select}
          value={depositTo}
          onChange={setDepositTo}
        >
          {funds.map((item) => (
            <Option
              key={item.symbol}
              value={item.symbol}
              disabled={
                item.symbol !== currentProduct?.baseCurrency &&
                !isVusdcOrVdarc(currentProduct?.symbol)
              }
            >
              <div className={s.select_item}>
                <img src={item.icon} alt={item.symbol} />
                <span>{item.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </div>

      <Button
        size="large"
        className={s.submit_button}
        onClick={handleClick}
        shape="round"
        ghost={theme === 'dark'}
        block
        disabled={disabled}
      >
        Send Request
      </Button>
    </>
  );
};

export default SellProduct;
