import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';

import defaultLogo from '../../assets/images/default-token-logo.svg';
import { ProductOrderType } from './types';

export const selectProductsDomain = (state: ApplicationState) =>
  state.products || initialState;

export const makeSelectProducts = () =>
  createSelector(selectProductsDomain, (state) => state.strategies);

export const makeSelectProductsByCategory = (categoryId: number) =>
  createSelector(selectProductsDomain, (state) =>
    state.strategies.filter((strategy) => strategy.categoryId === categoryId)
  );

export const makeSelectCurrentProduct = (symbol: string) =>
  createSelector(makeSelectProducts(), (indexes) =>
    indexes.find((index) => index.symbol.toLowerCase() === symbol.toLowerCase())
  );

export const makeSelectIndexes = () =>
  createSelector(selectProductsDomain, (state) => state.indexes);

export const makeSelectCurrentIndex = (symbol: string) =>
  createSelector(makeSelectIndexes(), (indexes) =>
    indexes.find((index) => index.symbol.toLowerCase() === symbol.toLowerCase())
  );

export const makeSelectProductsLoading = () =>
  createSelector(selectProductsDomain, (state) => state.loading);

export const makeSelectStrategyChart = (symbol: string) =>
  createSelector(makeSelectProducts(), (products) => {
    const product = products.find((p) => p.symbol === symbol);
    if (product && product.chart) {
      return product.chart;
    }
    return [];
  });

export const makeSelectIndexChart = (symbol: string) =>
  createSelector(makeSelectIndexes(), (indexes) => {
    const index = indexes.find((p) => p.symbol === symbol);
    if (index && index.chart) {
      return index.chart;
    }
    return [];
  });

export const makeSelectProductsLogo = () =>
  createSelector(makeSelectProducts(), (products) => {
    const logos: { [key: string]: string } = {};
    products.forEach((product) => {
      logos[product.symbol] = product.files.logo || defaultLogo;
    });
    return logos;
  });

export const makeSelectStrategyIsTrading = (
  symbol: string,
  type: ProductOrderType
) =>
  createSelector(makeSelectCurrentProduct(symbol), (strategy) => {
    const trading = {
      hasTrading: false,
      isActive: false,
    };
    if (strategy && strategy.categoryId === 4) {
      trading.hasTrading = true;
      switch (type) {
        case 'Buy':
          trading.isActive = strategy.explanationPeriod.buyPeriod.isSuitable;
          return trading;
        case 'Sell':
          trading.isActive = strategy.explanationPeriod.sellPeriod.isSuitable;
          return trading;
        default:
          return trading;
      }
    }
    return trading;
  });
