import {
  all,
  fork,
  call,
  put,
  takeLatest,
  SagaReturnType,
} from 'redux-saga/effects';
import { transactionsActions } from './slice';
import { portfolioActions } from '../Portfolio/slice';
import mainApiProtected from '../../services/mainApiProtected';

function* fetchTransactions() {
  try {
    const {
      data,
    }: SagaReturnType<
      typeof mainApiProtected.fetchTransactionsHistory
    > = yield call(mainApiProtected.fetchTransactionsHistory);
    yield put(transactionsActions.fetchSuccess(data.items));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchTransactions() {
  yield takeLatest(
    [
      'LOAD_APP',
      transactionsActions.fetch.type,
      transactionsActions.canceled.type,
      portfolioActions.buy.type,
      portfolioActions.sell.type,
    ],
    fetchTransactions
  );
}

export default function* userSaga() {
  yield all([fork(watchFetchTransactions)]);
}
