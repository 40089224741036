import { AxiosRequestConfig } from 'axios';

import jwt_decode from 'jwt-decode';
import HttpClient from '../utils/http-client';
import appConfig from '../config/app';

import {
  PortfolioChartResponse,
  PortfolioResponse,
  ProductOrderResponse,
} from '../containers/Portfolio/types';
import { User, UpdateNotificationSettings } from '../containers/User/types';
import { Transaction } from '../containers/Transactions/types';
import { UserBalance, UserWallets } from '../containers/Funds/types';
import { Order, OrderFee } from '../containers/Products/types';
import {
  ReferralList,
  RewardList,
  RewardsChartData,
  RewardStatus,
  TotalRewards,
  UserLevel,
} from '../containers/ReferralsRewards/types';
import { DepositBonus } from '../containers/DepositBonus/types';

const { apiHost, tokenKey } = appConfig;

class MainApiProtected extends HttpClient {
  private static classInstance?: MainApiProtected;

  constructor() {
    super(`${apiHost}/api`);

    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError
    );
  };

  private handleRequest = (config: AxiosRequestConfig) => {
    const token = this.cookies.get(tokenKey);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      this.handleLogout();
    }

    return config;
  };

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MainApiProtected();
    }

    return this.classInstance;
  }

  private getUserIdFromToken = () => {
    const token = this.cookies.get(tokenKey);
    const decoded: any = jwt_decode(token);
    return decoded.id;
  };

  public fetchUserProfile = () =>
    this.instance.get<User>(`/users/${this.getUserIdFromToken()}/profile`, {
      params: { expand: 'settings' },
    });

  public fetchUserBalance = () =>
    this.instance.get<UserBalance>(
      `/users/${this.getUserIdFromToken()}/account`
    );

  public fetchPortfolioList = () =>
    this.instance.get<PortfolioResponse[]>('/orders/list');

  public fetchUserWallets = () =>
    this.instance.get<UserWallets>(
      `/users/${this.getUserIdFromToken()}/wallets`
    );

  public orderBuyEstimate = (data: Order) =>
    this.instance.post<OrderFee>('/order/estimate-buy', data);

  public orderBuy = (data: Order) =>
    this.instance.post<ProductOrderResponse>('/orders/buy', data);

  public orderSellEstimate = (data: Order) =>
    this.instance.post<OrderFee>('/order/estimate-sell', data);

  public orderSell = (data: Order) =>
    this.instance.post<ProductOrderResponse>('/orders/sell', data);

  public fetchPortfolioChart = () =>
    this.instance.get<PortfolioChartResponse>(
      `/orders/${this.getUserIdFromToken()}/portfolio`
    );

  public fetchTransactionsHistory = () =>
    this.instance.get<{ items: Transaction[] }>('/transactions/history', {
      params: { expand: 'conversion' },
    });

  public withdrawFunds = (data: {
    wallet_address: string;
    amount: number;
    currency: string;
    code: string;
  }) => this.instance.post('/transactions/withdraw', data);

  public updateAvatar = (data: FormData) =>
    this.instance.post<{ avatar: string }>('/user/avatar', data);

  public deleteAvatar = () => this.instance.delete('/user/avatar');

  public updateProfile = (data: {
    fullName?: string;
    location?: string;
    phone?: string;
    currency?: string;
    timezone?: string;
    theme?: string;
  }) =>
    this.instance.put<User>(
      `/users/${this.getUserIdFromToken()}/profile`,
      data,
      {
        params: { expand: 'settings' },
      }
    );

  // public uploadDocumentVerification = (data: FormData) =>
  //   this.instance.post<{ id?: string; passport?: string }>(
  //     '/user/documents-verification',
  //     data
  //   );

  public submitUserVerification = (data: { verification_id: string }) =>
    this.instance.post('/users/submit-verification', data);

  public enable2FA = () =>
    this.instance.get<{ key: string; uri: string }>(
      '/google-authenticator/enable'
    );

  public disable2FA = (data: { code: string }) =>
    this.instance.post('/google-authenticator/disable', data);

  public enable2FAVerify = (data: { code: string }) =>
    this.instance.post('/google-authenticator/verify', data);

  public fetchNotificationSettings = () =>
    this.instance.get<
      {
        notification: {
          createdAt: string;
          id: number;
          name: string;
          slug: string;
          type: null;
        };
      }[]
    >('/users/1087/notification-preferences');

  public updateNotificationSettings = (data: UpdateNotificationSettings) =>
    this.instance.post(
      `/users/${this.getUserIdFromToken()}/notification-preferences`,
      data
    );

  public changeUserPassword = (data: {
    current: string;
    password: string;
    confirm: string;
  }) => this.instance.post('/users/me/change-password', data);

  public sendOTPCode = () => this.instance.post('/user/send-code');

  public verifyOTPCode = (data: { code: string }) =>
    this.instance.post('/google-authenticator/verify', data);

  public fetchUserLevelInfo = () =>
    this.instance.get<UserLevel>('/user/level-info');

  public fetchUserTotalRewards = (status?: RewardStatus) =>
    this.instance.get<TotalRewards>('/rewards/total-rewards', {
      params: status ? { status } : {},
    });

  public fetchUserRewardList = () =>
    this.instance.get<RewardList>('/rewards/list');

  public fetchUserReferralList = () =>
    this.instance.get<ReferralList>('/rewards/referral-list');

  public fetchRewardsChart = (status?: RewardStatus) =>
    this.instance.get<RewardsChartData>('/rewards/chart', {
      params: status ? { status } : {},
    });

  public claimRewards = (type: 'referral' | 'reward') =>
    this.instance.post('/rewards/claim-rewards', null, { params: { type } });

  public cancelTransaction = (id: number) =>
    this.instance.delete(`/transaction/${id}/cancel`);

  public getDepositBonuses = () =>
    this.instance.get<{ data: DepositBonus[] }>(
      `/users/${this.getUserIdFromToken()}/deposit-bonuses`
    );
}

const mainApiProtected = MainApiProtected.getInstance();

export default mainApiProtected;
