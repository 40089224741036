import {
  all,
  fork,
  call,
  put,
  takeLatest,
  SagaReturnType,
} from 'redux-saga/effects';
import { rewardsActions } from './slice';
import mainApiProtected from '../../services/mainApiProtected';
import { formatRewardsChartData } from '../../utils/chart';
import { RewardsName, TotalAmountReward } from './types';
import { changeUserCurrency } from '../User/slice';

function* fetchUserLevelInfo() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchUserLevelInfo> = yield call(
      mainApiProtected.fetchUserLevelInfo
    );
    yield put(rewardsActions.setLevelInfo(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUserRewardsChart() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchRewardsChart> = yield call(
      mainApiProtected.fetchRewardsChart,
      'DONE'
    );

    yield put(rewardsActions.setChart(formatRewardsChartData(data)));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUserTotalRewards() {
  try {
    const total: SagaReturnType<
      typeof mainApiProtected.fetchUserTotalRewards
    > = yield call(mainApiProtected.fetchUserTotalRewards);
    const totalDone: SagaReturnType<
      typeof mainApiProtected.fetchUserTotalRewards
    > = yield call(mainApiProtected.fetchUserTotalRewards, 'DONE');

    const totalPending = Object.keys(total.data).reduce<TotalAmountReward>(
      (acc, name) => {
        const copyAcc = { ...acc };
        copyAcc.totalAmount =
          acc.totalAmount +
          (total.data[name as RewardsName].totalAmount -
            totalDone.data[name as RewardsName].totalAmount);

        copyAcc.amountInBtc =
          acc.amountInBtc +
          (total.data[name as RewardsName].amountInBtc -
            totalDone.data[name as RewardsName].amountInBtc);

        return copyAcc;
      },
      { totalAmount: 0, amountInBtc: 0 }
    );

    yield put(rewardsActions.setTotalPending(totalPending));
    yield put(rewardsActions.setTotalRewards(totalDone.data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUserRewardList() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchUserRewardList> = yield call(
      mainApiProtected.fetchUserRewardList
    );
    yield put(rewardsActions.setRewardList(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUserReferralList() {
  try {
    const {
      data,
    }: SagaReturnType<
      typeof mainApiProtected.fetchUserReferralList
    > = yield call(mainApiProtected.fetchUserReferralList);
    yield put(rewardsActions.setReferralList(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllDataRewards() {
  yield all([
    fetchUserLevelInfo(),
    fetchUserRewardsChart(),
    fetchUserRewardList(),
    fetchUserReferralList(),
  ]);
  yield put(rewardsActions.fetchAllDataSuccess());
}

function* watchFetchUserLevelInfo() {
  yield takeLatest([rewardsActions.fetchAllData], fetchAllDataRewards);
}

function* watchFetchUserTotalRewards() {
  yield takeLatest(
    ['LOAD_APP', changeUserCurrency.type],
    fetchUserTotalRewards
  );
}

export default function* rewardsSaga() {
  yield all([fork(watchFetchUserLevelInfo), fork(watchFetchUserTotalRewards)]);
}
