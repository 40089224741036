import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';

export const selectNoticesDomain = (state: ApplicationState) =>
  state.notifications || initialState;

export const makeSelectNotices = () =>
  createSelector(selectNoticesDomain, (state) => state.data);

export const makeSelectAmountNewNotices = () =>
  createSelector(selectNoticesDomain, (state) => state.amount);
