import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Transaction, TransactionsState } from './types';

export const initialState: TransactionsState = {
  loading: false,
  data: [],
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state, action: PayloadAction<Transaction[]>) => {
      state.loading = false;
      state.data = action.payload;
    },
    addTransaction: (state, action: PayloadAction<Transaction>) => {
      state.data.splice(0, 0, action.payload);
    },
    canceled: () => {},
  },
});

export const transactionsActions = transactionsSlice.actions;
export const transactionsReducer = transactionsSlice.reducer;
