type Config = {
  isProd: boolean;
  domain: string;
  apiHost: string;
  mainHost: string;
  tokenKey: string;
  wsHost: string;
};

const config: Config = {
  isProd: process.env.NODE_ENV === 'production',
  domain: process.env.REACT_APP_VEGAX_DOMAIN || '',
  apiHost: process.env.REACT_APP_API_HOST || '',
  mainHost: process.env.REACT_APP_MAIN_HOST || '',
  wsHost: process.env.REACT_APP_WS_HOST || '',
  tokenKey: process.env.REACT_APP_TOKEN_KEY || '',
};

export default config;
