import {
  all,
  fork,
  call,
  put,
  takeLatest,
  select,
  SagaReturnType,
} from 'redux-saga/effects';

import { currenciesActions } from './slice';
import mainApi from '../../services/mainApi';
import { portfolioActions } from '../Portfolio/slice';
import { UserState } from '../User/types';
import { changeUserCurrency } from '../User/slice';

function* fetchCurrencies() {
  try {
    const { data }: SagaReturnType<typeof mainApi.fetchCurrencies> = yield call(
      mainApi.fetchCurrencies
    );
    const { user }: UserState = yield select((state) => state.user);
    const userCurrency = data.find((c) => c.id === user.settings.currency);
    const price = userCurrency ? Number(userCurrency.price) : 1;

    const currencies = data.map((c) => ({
      ...c,
      price: Number(c.price) * (c.id === 'KRW' ? 1 : price),
      change: Number(c.change),
    }));
    yield put(currenciesActions.fetchSuccess(currencies));
  } catch (e) {
    yield put(currenciesActions.fetchFailure(e.message));
  }
}

function* watchFetchCurrencies() {
  yield takeLatest(
    [
      'LOAD_APP',
      currenciesActions.fetch.type,
      portfolioActions.buy.type,
      portfolioActions.sell.type,
      changeUserCurrency.type,
    ],
    fetchCurrencies
  );
}

export default function* currenciesSaga() {
  yield all([fork(watchFetchCurrencies)]);
}
