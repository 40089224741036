import HttpClient from '../utils/http-client';
import config from '../config/app';

import { Currency, FiatCurrencies } from '../containers/Currencies/types';
import {
  Index,
  StrategyChartResponse,
  Strategy,
  IndexChartResponse,
} from '../containers/Products/types';
import { GidePost } from '../containers/GuideCentral/types';

const { apiHost } = config;

class MainApi extends HttpClient {
  private static classInstance?: MainApi;

  private constructor() {
    super(`${apiHost}/api`);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MainApi();
    }

    return this.classInstance;
  }

  public fetchStrategies = ({
    currency = 'USD',
  }: {
    currency?: FiatCurrencies;
  }) =>
    this.instance.get<Strategy[]>('/products/', {
      params: {
        type: 'strategy',
        is_published: true,
      },
    });

  public fetchIndexes = () =>
    this.instance.get<Index[]>('/products/', {
      params: {
        type: 'index',
        is_published: true,
      },
    });

  public fetchProduct = (symbol: string) =>
    this.instance.get<Index[]>(`/products/${symbol}`, {
      params: {
        is_published: true,
      },
    });

  public fetchCurrencies = () => this.instance.get<Currency[]>('/currencies/');

  public fetchStrategyChart = (symbol: string) =>
    this.instance.get<StrategyChartResponse>(`${symbol.toLowerCase()}/chart`);

  public fetchIndexChart = (symbol: string) =>
    this.instance.get<IndexChartResponse>(`${symbol.toLowerCase()}/chart`);

  public fetchMediumPosts = () =>
    this.instance.get<GidePost[]>('medium-posts/get');
}

const mainApi = MainApi.getInstance();

export default mainApi;
