import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';

export const selectModalDomain = (state: ApplicationState) =>
  state.modal || initialState;

export const makeSelectAddFundsModal = () =>
  createSelector(selectModalDomain, (state) => state.addFunds);

export const makeSelectTradeProductsModal = () =>
  createSelector(selectModalDomain, (state) => state.tradeProducts);

export const makeSelectConfirmTxModal = () =>
  createSelector(selectModalDomain, (state) => state.confirmTx);

export const makeSelectOtpModal = () =>
  createSelector(selectModalDomain, (state) => state.OTP);

export const makeSelectGoogle2FAModal = () =>
  createSelector(selectModalDomain, (state) => state.goggle2FA);

export const makeSelectSubscriptionKRW = () =>
  createSelector(selectModalDomain, (state) => state.subscriptionKRW);

export const makeSelectSubscriptionProducts = () =>
  createSelector(selectModalDomain, (state) => state.subscriptionProducts);

export const makeConfirmation = () =>
  createSelector(selectModalDomain, (state) => state.confirmation);
