import React from 'react';
import Input from 'react-otp-input';
import cx from 'classnames';

import s from './OtpInput.module.less';

type Props = {
  value: string;
  onChange: (value: string) => void;
  numInputs?: number;
  className?: string;
};

const OtpInput = ({ value, onChange, numInputs = 4, className }: Props) => (
  <Input
    value={value}
    onChange={onChange}
    numInputs={numInputs}
    isInputNum
    shouldAutoFocus
    containerStyle={cx(s.container, className && className)}
    inputStyle={s.input}
  />
);

export default OtpInput;
