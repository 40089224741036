import {
  all,
  fork,
  call,
  put,
  takeLatest,
  SagaReturnType,
} from 'redux-saga/effects';

import { fundsActions } from './slice';
import mainApiProtected from '../../services/mainApiProtected';
import { portfolioActions } from '../Portfolio/slice';
import { transactionsActions } from '../Transactions/slice';
import { changeUserCurrency } from '../User/slice';

function* fetchWallets() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchUserWallets> = yield call(
      mainApiProtected.fetchUserWallets
    );

    yield put(fundsActions.setWallets(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUserBalance() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchUserBalance> = yield call(
      mainApiProtected.fetchUserBalance
    );

    yield put(fundsActions.fetchFundsSuccess(data));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchWallets() {
  yield takeLatest(['LOAD_APP'], fetchWallets);
}

function* watchFetchUserBalance() {
  yield takeLatest(
    [
      'LOAD_APP',
      fundsActions.fetchFunds.type,
      portfolioActions.buy.type,
      portfolioActions.sell.type,
      transactionsActions.canceled.type,
      changeUserCurrency.type,
    ],
    fetchUserBalance
  );
}

export default function* fundsSaga() {
  yield all([fork(watchFetchWallets), fork(watchFetchUserBalance)]);
}
