import {
  all,
  fork,
  call,
  put,
  takeLatest,
  takeEvery,
  Effect,
  SagaReturnType,
} from 'redux-saga/effects';

import { productsActions } from './slice';
import mainApi from '../../services/mainApi';
import {
  formatIndexChartData,
  formatStrategyChartData,
} from '../../utils/chart';
import { changeUserCurrency } from '../User/slice';

function* fetchStrategies(action: Effect) {
  try {
    const { data }: SagaReturnType<typeof mainApi.fetchStrategies> = yield call(
      mainApi.fetchStrategies,
      action.payload
    );
    const strategies = data.map((item) => {
      let { baseCurrency } = item;
      if (item.symbol === 'VUSDC') {
        baseCurrency = 'USDC';
      }
      if (item.symbol === 'VDARC') {
        baseCurrency = 'BTC';
      }
      return {
        ...item,
        baseCurrency,
      };
    });
    yield put(productsActions.setStrategies(strategies));
  } catch (e) {
    console.error(e);
  }
}

function* fetchIndexes() {
  try {
    const { data }: SagaReturnType<typeof mainApi.fetchIndexes> = yield call(
      mainApi.fetchIndexes
    );
    yield put(productsActions.setIndexes(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchStrategyChart(action: Effect) {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApi.fetchStrategyChart> = yield call(
      mainApi.fetchStrategyChart,
      action.payload
    );
    const chartData = formatStrategyChartData(data);

    yield put(
      productsActions.fetchStrategyChartSuccess({
        symbol: action.payload,
        chartData,
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchIndexChart(action: Effect) {
  try {
    const { data }: SagaReturnType<typeof mainApi.fetchIndexChart> = yield call(
      mainApi.fetchIndexChart,
      action.payload
    );
    const chartData = formatIndexChartData(data);

    yield put(
      productsActions.fetchIndexChartSuccess({
        symbol: action.payload,
        chartData,
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchStrategies() {
  yield takeLatest(['LOAD_APP', changeUserCurrency.type], fetchStrategies);
}

function* watchFetchIndexes() {
  yield takeLatest(['LOAD_APP'], fetchIndexes);
}

function* watchFetchStrategyChart() {
  yield takeEvery(productsActions.fetchStrategyChart.type, fetchStrategyChart);
}

function* watchFetchIndexChart() {
  yield takeEvery(productsActions.fetchIndexChart.type, fetchIndexChart);
}

export default function* productsSaga() {
  yield all([
    fork(watchFetchStrategies),
    fork(watchFetchIndexes),
    fork(watchFetchStrategyChart),
    fork(watchFetchIndexChart),
  ]);
}
