import {
  all,
  fork,
  call,
  put,
  takeLatest,
  SagaReturnType,
} from 'redux-saga/effects';

import { gideCentralActions } from './slice';
import mainApi from '../../services/mainApi';

function* fetchPosts() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApi.fetchMediumPosts> = yield call(
      mainApi.fetchMediumPosts
    );

    yield put(gideCentralActions.fetchSuccess(data));
  } catch (e) {
    yield put(gideCentralActions.fetchFailure());
  }
}

function* watchFetchPost() {
  yield takeLatest([gideCentralActions.fetch.type], fetchPosts);
}

export default function* gideCentralSaga() {
  yield all([fork(watchFetchPost)]);
}
