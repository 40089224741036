import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { Cookies } from 'react-cookie';

import appConfig from '../config/app';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected readonly cookies: Cookies;

  protected constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      transformResponse: (data) => {
        if (data) {
          return camelizeKeys(JSON.parse(data), (key, convert) =>
            /^[A-Z0-9_]+$/.test(key) ? key : convert(key)
          );
        }
        return data;
      },
    });

    this.cookies = new Cookies();

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleResponse = (response: AxiosResponse) => response;

  protected handleError = (err: any) => {
    let error = 'An unknown error occurred';

    if (err.response?.status === 401) {
      this.handleLogout();
    } else if (err.response?.data?.message) {
      error = err.response.data.message;
    } else if (!err.response && err.message) {
      error = err.message;
    }

    return Promise.reject(error);
  };

  protected handleLogout = () => {
    this.cookies.remove(appConfig.tokenKey, { domain: appConfig.domain });
    window.location.replace(`${appConfig.mainHost}/login`);
  };
}

export default HttpClient;
