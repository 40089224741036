import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GidePost, GideCentralState } from './types';

export const initialState: GideCentralState = {
  data: [],
  loading: false,
};

const gideCentralSlice = createSlice({
  name: 'gideCentral',
  initialState,
  reducers: {
    fetch(state) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<GidePost[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchFailure(state) {
      state.loading = false;
    },
  },
});

export const gideCentralActions = gideCentralSlice.actions;
export const gideCentralReducer = gideCentralSlice.reducer;
