import React from 'react';
import { message } from 'antd';

import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import s from './CopyButton.module.less';

type Props = {
  elementRef: React.MutableRefObject<any>;
};

const CopyButton = ({ elementRef }: Props) => {
  const onClick = () => {
    elementRef.current.select();
    document.execCommand('copy');
    message.success('Copied!');
  };

  return (
    <button type="button" onClick={onClick} className={s.button}>
      <CopyIcon />
    </button>
  );
};

export default CopyButton;
