import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { UserState, User, NotificationSettings } from './types';
import { FiatCurrencies } from '../Currencies/types';

const currency = (localStorage.getItem('currency') || 'USD') as FiatCurrencies;

export const changeUserCurrency = createAction<{ currency: FiatCurrencies }>(
  'changeUserCurrency'
);

export const initialState: UserState = {
  user: {
    id: 0,
    createdAt: 0,
    email: '',
    level: 'NEWCOMER',
    referralCode: '',
    name: '',
    participantFrom: null,
    phone: null,
    avatar: null,
    isVerified: false,
    documentsVerifiedStatus: 'NOT_VERIFIED',
    isEnableGoogleAuthenticator: false,
    country: null,
    settings: {
      currency,
      location: '',
    },
  },
  notificationSettings: [],
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUser(state) {
      state.loading = true;
    },
    fetchUserSuccess(state, action: PayloadAction<User>) {
      state.loading = false;
      state.user = action.payload;
    },
    updateAvatar(state, action: PayloadAction<string | null>) {
      state.user.avatar = action.payload;
    },
    set2FA(state, action: PayloadAction<boolean>) {
      state.user.isEnableGoogleAuthenticator = action.payload;
    },
    setNotificationSettings(
      state,
      action: PayloadAction<NotificationSettings>
    ) {
      state.notificationSettings = action.payload;
    },
    setCurrency(state, action: PayloadAction<FiatCurrencies>) {
      state.user.settings.currency = action.payload;
      localStorage.setItem('currency', action.payload);
    },
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
