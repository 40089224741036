import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';

export const selectCurrenciesDomain = (state: ApplicationState) =>
  state.currencies || initialState;

export const makeSelectCurrencies = () =>
  createSelector(selectCurrenciesDomain, (state) => state.data);

export const makeSelectCurrency = (id: string) =>
  createSelector(makeSelectCurrencies(), (currencies) =>
    currencies.find((currency) => currency.id === id)
  );

export const makeSelectCurrencyPrice = (id: string) =>
  createSelector(makeSelectCurrency(id), (currency) => currency?.price || 0);

export const makeSelectCurrenciesLoading = () =>
  createSelector(selectCurrenciesDomain, (state) => state.loading);
