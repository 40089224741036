import React from 'react';

import { formatNumber } from '../../../../utils/format';
import s from './NotificationBox.module.less';

export const formatCurrencyAmount = (amount?: string) => {
  if (!amount) return '';
  let amountNum = Number(amount);
  amountNum = amountNum < 0 ? amountNum * -1 : amountNum;
  return formatNumber(amountNum);
};

export const formatText = (slug: string, values: Record<string, any>) => {
  switch (slug) {
    case 'order_sell_pending':
      return (
        <>
          <h5 className={s.item_title}>Transaction in Process</h5>
          <p className={s.item_msg}>
            You have requested to sell{' '}
            <strong>
              {values.amount} {values.symbol}
            </strong>
            .
          </p>
        </>
      );
    case 'order_sell_completed':
      return (
        <>
          <h5 className={s.item_title}>
            Transaction Complete - {values.symbol}
          </h5>
          <p className={s.item_msg}>
            You have successfully sold{' '}
            <strong>
              {values.amount} {values.symbol}
            </strong>
            for{' '}
            <strong>
              {values.transactionAmount} {values.currency}
            </strong>
            .
          </p>
        </>
      );
    case 'order_buy_pending':
      return (
        <>
          <h5 className={s.item_title}>Transaction in Process</h5>
          <p className={s.item_msg}>
            You have requested{' '}
            <strong>
              {values.transactionAmount} {values.currency}
            </strong>
            of <strong>{values.symbol}</strong>.
          </p>
        </>
      );
    case 'order_buy_completed':
      return (
        <>
          <h5 className={s.item_title}>
            Transaction Complete - {values.symbol}
          </h5>
          <p className={s.item_msg}>
            You have successfully received{' '}
            <strong>
              {values.amount} {values.symbol}
            </strong>{' '}
            for{' '}
            <strong>
              {values.transactionAmount} {values.currency}
            </strong>
            .
          </p>
        </>
      );
    case 'transaction_withdrawal_pending':
      return (
        <>
          <h5 className={s.item_title}>Withdrawal Request in Process</h5>
          <p className={s.item_msg}>
            We have received your withdrawal request. Please wait up to 24 hours
            for the withdrawal process to complete and appear in you account.
          </p>
        </>
      );
    case 'transaction_withdrawal_completed':
      return (
        <>
          <h5 className={s.item_title}>Withdrawal Request Complete</h5>
          <p className={s.item_msg}>
            You have sent{' '}
            <strong>
              {values.transactionAmount} {values.currency}
            </strong>{' '}
            to wallet: {values.walletAddress}
          </p>
        </>
      );
    case 'transaction_deposit_pending':
      return (
        <>
          <h5 className={s.item_title}>Deposit Confirmation Form Submitted</h5>
          <p className={s.item_msg}>
            We have received your deposit confirmation form. Please wait up to
            24 hours for the deposit details to appear in your account.
          </p>
        </>
      );
    case 'transaction_deposit_completed':
      return (
        <>
          <h5 className={s.item_title}>
            Deposit Confirmed - {values.currency} Received!
          </h5>
          <p className={s.item_msg}>
            You have received{' '}
            <strong>
              {values.transactionAmount} {values.currency}
            </strong>{' '}
            from wallet: {values.walletAddress}
          </p>
        </>
      );
    case 'order_transfer_sell_pending':
      return (
        <>
          <h5 className={s.item_title}>Transfer in process</h5>
          <p className={s.item_msg}>
            You have requested to convert{' '}
            <strong>
              {values.amount} {values.symbol}
            </strong>{' '}
            to <strong>{values.currency}</strong>.
          </p>
        </>
      );
    case 'order_transfer_buy_completed':
      return (
        <>
          <h5 className={s.item_title}>Transfer Complete</h5>
          <p className={s.item_msg}>
            You have successfully converted{' '}
            <strong>
              {values.amount} {values.symbol}
            </strong>{' '}
            to{' '}
            <strong>
              {values.transactionAmount} {values.currency}
            </strong>
            .
          </p>
        </>
      );
    case 'documents_verified_pending':
      return (
        <>
          <h5 className={s.item_title}>
            Identity Verification Request in Review
          </h5>
          <p className={s.item_msg}>
            You have successfully submitted your identity verification. We are
            reviewing your identity documents and will notify you once complete.
          </p>
        </>
      );
    case 'documents_verified_verified':
      return (
        <>
          <h5 className={s.item_title}>Identity Verification Complete!</h5>
          <p className={s.item_msg}>
            You have successfully verified your identity and can use all of
            VegaX services!
          </p>
        </>
      );
    case 'documents_verified_declined':
      return (
        <>
          <h5 className={s.item_title}>Identity Verification Failed</h5>
          <p className={s.item_msg}>
            Your identity verification documents have been rejected. Please
            resubmit your identity verification details for review.
          </p>
        </>
      );
    case 'referral_joined_bonus':
      return (
        <>
          <h5 className={s.item_title}>Welcome to VegaX!</h5>
          <p className={s.item_msg}>
            With your friend’s referral code, your{' '}
            <strong>{values.bonus} reward</strong> is available when you{' '}
            <strong>add a deposit & allocate</strong> to a VegaX strategy!
          </p>
        </>
      );
    case 'referral_user_made_investment':
      return (
        <>
          <h5 className={s.item_title}>
            Referral Rewards - You just got {values.bonus}!
          </h5>
          <p className={s.item_msg}>
            Referral’s are a great way to earn rewards! Congrats on your VegaX
            investment, put that {values.bonus} to good use! Use your referral
            code to tell a friend {values.referralCode}
          </p>
        </>
      );
    case 'referral_user_joined':
      return (
        <>
          <h5 className={s.item_title}>
            Referral Rewards - New Referral in Process!
          </h5>
          <p className={s.item_msg}>
            Good News! A new user has registered on VegaX with your referral
            code. You’ll earn {values.bonus} when this user deposits & invests.
          </p>
        </>
      );
    case 'referral_investment_bonus':
      return (
        <>
          <h5 className={s.item_title}>
            Referral Rewards - You just got {values.bonus} for a referral!
          </h5>
          <p className={s.item_msg}>
            GREAT NEWS - Your referral has made a new transaction! For your
            referral you received {values.bonus}.
          </p>
        </>
      );
    default:
      return null;
  }
};
