import React, { useEffect, useState } from 'react';
import { Modal, Button, message, Skeleton } from 'antd';

import InfoTooltip from '../../../../components/InfoTooltip';
import TradingPeriod from '../TradingPeriod';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectConfirmTxModal } from '../../../ModalProvider/selectors';
import { makeSelectCurrencyPrice } from '../../../Currencies/selectors';
import {
  makeSelectProductsLogo,
  makeSelectStrategyIsTrading,
} from '../../selectors';
import { modalActions } from '../../../ModalProvider/slice';
import mainApiProtected from '../../../../services/mainApiProtected';
import { portfolioActions } from '../../../Portfolio/slice';
import { makeSelect2FA, makeSelectUserCurrency } from '../../../User/selectors';
import {
  formatCurrencyNumber,
  formatNumber,
  getHoldTimeDays,
} from '../../../../utils/format';
import appConfig from '../../../../config/app';
import { Order, OrderFee } from '../../types';

import s from './ConfirmTxModal.module.less';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/tx-direction.svg';
import successImg from '../../../../assets/images/tx-success.svg';
import transferImg from '../../../../assets/icons/transfer.svg';

const isProductHasLockupPeriod = (symbol: string) => {
  switch (symbol) {
    case 'VEBE':
    case 'VEEE':
      return true;
    default:
      return false;
  }
};

const defaultFee: OrderFee = { transactionFee: 0, penaltyFee: 0 };

const ConfirmTxModal = () => {
  const dispatch = useAppDispatch();
  const { visible, order } = useAppSelector(makeSelectConfirmTxModal());
  const is2FA = useAppSelector(makeSelect2FA());
  const currencyPrice = useAppSelector(
    makeSelectCurrencyPrice(order?.currency || 'BTC')
  );
  const trading = useAppSelector(
    makeSelectStrategyIsTrading(order?.product || '', order?.type || 'Buy')
  );
  const productsLogo = useAppSelector(makeSelectProductsLogo());
  const userCurrency = useAppSelector(makeSelectUserCurrency());

  const [transferTo, setTransferTo] = useState<string | undefined>(undefined);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEstimating, setIsEstimating] = useState(false);
  const [orderFee, setOrderFee] = useState<OrderFee>(defaultFee);
  const [visibleConvertProduct, setVisibleConvertProduct] = useState(false);
  const [doesNotWantToConvert, setDoesNotWantToConvert] = useState(false);

  const withLockupPeriod = isProductHasLockupPeriod(order?.product || '');
  // useEffect(() => {
  //   if (order) {
  //     setTransferTo(order.productForExchange);
  //     setIsEstimating(true);
  //     mainApiProtected[
  //       order.type === 'Buy' ? 'orderBuyEstimate' : 'orderSellEstimate'
  //     ]({
  //       product: order.product,
  //       amount: order.type === 'Buy' ? order.currencyAmount : order.amount,
  //       currency: order.currency,
  //     })
  //       .then(({ data }) => {
  //         setOrderFee(data);
  //         setIsEstimating(false);
  //       })
  //       .catch((err) => {
  //         onClose();
  //         message.error(err);
  //       });
  //   }
  // }, [order]);

  // const onClose = () => {
  //   dispatch(
  //     modalActions.open({ name: 'confirmTx', data: { visible: false } })
  //   );
  //   setIsLoading(false);
  //   setIsSuccess(false);
  //   setIsEstimating(false);
  //   setOrderFee(defaultFee);
  //   setTransferTo(undefined);
  //   setVisibleConvertProduct(false);
  //   setDoesNotWantToConvert(false);
  // };

  // const orderBuy = (orderValues: Order) => {
  //   setIsLoading(true);
  //   return mainApiProtected
  //     .orderBuy(orderValues)
  //     .then(() => {
  //       dispatch(portfolioActions.buy());
  //       setIsSuccess(true);
  //     })
  //     .catch((err: string) => {
  //       message.error(err);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const orderSell = (orderValues: Order) => {
  //   setIsLoading(true);
  //   return mainApiProtected
  //     .orderSell(orderValues)
  //     .then(() => {
  //       dispatch(portfolioActions.sell());
  //       setIsSuccess(true);
  //     })
  //     .catch((err) => {
  //       message.error(err);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const onConfirmClick = () => {
  //   if (order) {
  //     const data: Order = {
  //       product: order.product,
  //       currency: order.currency,
  //       amount: 0,
  //     };

  //     if (order?.type === 'Buy') {
  //       data.amount = order.currencyAmount;

  //       if (is2FA) {
  //         dispatch(
  //           modalActions.open({
  //             name: 'goggle2FA',
  //             data: {
  //               visible: true,
  //               callback: (code) => {
  //                 data.code = code;
  //                 return orderBuy(data);
  //               },
  //             },
  //           })
  //         );
  //       } else {
  //         orderBuy(data);
  //       }
  //     } else if (order?.type === 'Sell') {
  //       if (!transferTo && !doesNotWantToConvert && order.product !== 'VUSDC') {
  //         setVisibleConvertProduct(true);
  //         return;
  //       }
  //       data.amount = order.amount;
  //       if (orderFee.penaltyFee > 0) {
  //         data.agree_to_penalty = true;
  //       }
  //       if (transferTo) {
  //         data.product_for_exchange = transferTo;
  //       }
  //       if (is2FA) {
  //         dispatch(
  //           modalActions.open({
  //             name: 'goggle2FA',
  //             data: {
  //               visible: true,
  //               callback: (code) => {
  //                 data.code = code;
  //                 return orderSell(data);
  //               },
  //             },
  //           })
  //         );
  //       } else {
  //         orderSell(data);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (doesNotWantToConvert) {
  //     onConfirmClick();
  //   }
  // }, [doesNotWantToConvert]);

  // const onConvertClick = () => {
  //   if (order) {
  //     dispatch(
  //       modalActions.open({
  //         name: 'confirmTx',
  //         data: {
  //           visible: true,
  //           order: {
  //             ...order,
  //             productForExchange: 'VUSDC',
  //           },
  //         },
  //       })
  //     );
  //     setVisibleConvertProduct(false);
  //   }
  // };

  // const helpLink = (
  //   <a
  //     href={`${appConfig.mainHost}/guide-central/investment#3-months-lock-up-period`}
  //     target="_blank"
  //     rel="noreferrer"
  //   >
  //     More details about lock-up period
  //   </a>
  // );

  // const tooltipText =
  //   'Transaction will get pending status upon clicking on a button "Confirm". Number of  tokens may slightly differ once transaction is confirmed. It is based on network workload.';

  // const renderConfirm = () => {
  //   switch (order?.type) {
  //     case 'Buy':
  //       return (
  //         <div className={s.content}>
  //           <h3>Buy Confirmation</h3>
  //           {trading.hasTrading && (
  //             <TradingPeriod isActive={trading.isActive} type="Buy" />
  //           )}
  //           <ul>
  //             <li>
  //               <p>Amount</p>
  //               <p>
  //                 <strong className={s.lg}>
  //                   {order?.currencyAmount} {order?.currency}
  //                 </strong>
  //                 {formatCurrencyNumber(order.fiatAmount, false, userCurrency)}
  //               </p>
  //             </li>
  //             <li>
  //               <p>
  //                 Estimated tokens buy{' '}
  //                 <InfoTooltip text={tooltipText} placement="bottom" />
  //               </p>
  //               {isEstimating ? (
  //                 <Skeleton.Button active size="small" />
  //               ) : (
  //                 <p>
  //                   <strong>
  //                     {Math.round(
  //                       ((order.currencyAmount - orderFee.transactionFee) *
  //                         currencyPrice) /
  //                         (order.fiatAmount / order.amount)
  //                     )}{' '}
  //                     {order?.product}
  //                   </strong>
  //                 </p>
  //               )}
  //             </li>
  //             <li>
  //               <p>Approximate Transaction Fee</p>
  //               {isEstimating ? (
  //                 <Skeleton.Button active size="small" />
  //               ) : (
  //                 <p>
  //                   {formatNumber(orderFee.transactionFee, 8)} {order?.currency}
  //                 </p>
  //               )}
  //             </li>
  //           </ul>
  //           {withLockupPeriod && (
  //             <p>
  //               By clicking the confirmation button, you acknowledge that your
  //               investment will be <strong>locked for 3 months period.</strong>
  //             </p>
  //           )}
  //           <Button
  //             type="primary"
  //             shape="round"
  //             block
  //             onClick={onConfirmClick}
  //             loading={isLoading}
  //             disabled={isEstimating}
  //           >
  //             Confirm
  //           </Button>
  //           {withLockupPeriod && helpLink}
  //         </div>
  //       );
  //     case 'Sell': {
  //       const amountHoldDays = getHoldTimeDays(order?.created || Date.now());
  //       const hasLockupPeriod = orderFee.penaltyFee > 0;

  //       return (
  //         <div className={s.content}>
  //           <h3>
  //             {/* eslint-disable-next-line no-nested-ternary */}
  //             {transferTo
  //               ? 'Transfer Confirmation'
  //               : withLockupPeriod && !isEstimating && hasLockupPeriod
  //               ? 'Are you sure you want to sell?'
  //               : 'Sell Confirmation'}
  //           </h3>
  //           {trading.hasTrading && (
  //             <TradingPeriod isActive={trading.isActive} type="Sell" />
  //           )}
  //           {withLockupPeriod && !isEstimating && hasLockupPeriod && (
  //             <p className={s.warning}>
  //               Currently you still have lock up period left. If you sell them
  //               now, you <strong>will not received any profit</strong> and{' '}
  //               <strong>will be charged</strong> for the management fees.
  //             </p>
  //           )}

  //           <ul>
  //             <li>
  //               <p>
  //                 Estimated Amount
  //                 <InfoTooltip text={tooltipText} placement="bottom" />
  //               </p>
  //               {isEstimating ? (
  //                 <Skeleton.Button active size="small" />
  //               ) : (
  //                 <p>
  //                   <strong>
  //                     {formatNumber(
  //                       order?.currencyAmount -
  //                         orderFee.transactionFee -
  //                         orderFee.penaltyFee,
  //                       8
  //                     )}{' '}
  //                     {order?.currency}
  //                   </strong>
  //                   {formatCurrencyNumber(
  //                     (order?.currencyAmount -
  //                       orderFee.transactionFee -
  //                       orderFee.penaltyFee) *
  //                       currencyPrice,
  //                     false,
  //                     userCurrency
  //                   )}
  //                 </p>
  //               )}
  //             </li>
  //             <li>
  //               <p>Tokens will be sold</p>
  //               <p>
  //                 <strong className={s.lg}>
  //                   {order?.amount} {order?.product}
  //                 </strong>
  //               </p>
  //             </li>
  //             <li>
  //               <p>Approximate Transaction Fee</p>
  //               {isEstimating ? (
  //                 <Skeleton.Button active size="small" />
  //               ) : (
  //                 <p>
  //                   {formatNumber(orderFee.transactionFee, 8)} {order?.currency}
  //                 </p>
  //               )}
  //             </li>
  //             {withLockupPeriod && hasLockupPeriod && (
  //               <li>
  //                 <p>Penalty Fee</p>
  //                 <p>
  //                   {formatNumber(orderFee.penaltyFee, 8)} {order?.currency}
  //                 </p>
  //               </li>
  //             )}
  //           </ul>

  //           {transferTo && (
  //             <>
  //               <img className={s.transferIcon} src={transferImg} alt="->" />
  //               <ul>
  //                 <li>
  //                   <p>Transfer to {transferTo}</p>
  //                   <p>
  //                     <strong>
  //                       {`${formatNumber(
  //                         (order?.currencyAmount -
  //                           orderFee.transactionFee -
  //                           orderFee.penaltyFee) *
  //                           currencyPrice,
  //                         0
  //                       )} USDC`}
  //                     </strong>
  //                   </p>
  //                 </li>
  //               </ul>
  //             </>
  //           )}

  //           {withLockupPeriod ? (
  //             <>
  //               {helpLink}
  //               <Button type="primary" shape="round" block onClick={onClose}>
  //                 {hasLockupPeriod ? 'Cancel' : 'Continue to lock'}
  //               </Button>
  //               <Button
  //                 type="primary"
  //                 shape="round"
  //                 ghost
  //                 block
  //                 onClick={onConfirmClick}
  //                 loading={isLoading}
  //                 disabled={isEstimating}
  //               >
  //                 {transferTo ? 'Confirm to transfer' : 'Confirm to sell'}
  //               </Button>
  //             </>
  //           ) : (
  //             <Button
  //               type="primary"
  //               shape="round"
  //               block
  //               onClick={onConfirmClick}
  //               loading={isLoading}
  //               disabled={isEstimating}
  //             >
  //               {transferTo ? 'Confirm to transfer' : 'Confirm to sell'}
  //             </Button>
  //           )}
  //         </div>
  //       );
  //     }
  //     default:
  //       return null;
  //   }
  // };

  // const renderSuccess = () => {
  //   switch (order?.type) {
  //     case 'Buy':
  //       return (
  //         <div className={s.success}>
  //           <img src={successImg} alt="done" />
  //           <h3>Buying in Progress</h3>
  //           <p>
  //             You will get a notification once your transaction is complete.
  //           </p>
  //           <Button type="primary" shape="round" onClick={onClose}>
  //             Close
  //           </Button>
  //         </div>
  //       );
  //     case 'Sell':
  //       return (
  //         <div className={s.success}>
  //           <img src={successImg} alt="done" />
  //           <h3>
  //             {transferTo ? 'Processing Transfer' : 'Selling in Progress'}
  //           </h3>
  //           <p>
  //             {transferTo &&
  //               `You're converting ${order.product} to ${order.productForExchange} Investment Product.`}
  //             <br />
  //             You will get a notification once your transaction is complete.
  //           </p>
  //           {transferTo && (
  //             <div className={s.transferTo}>
  //               <span>
  //                 <img src={productsLogo[order.product]} alt="logo" />{' '}
  //                 {order.product}
  //               </span>
  //               <span>
  //                 <ArrowIcon />
  //               </span>
  //               <span>
  //                 <img src={productsLogo[transferTo]} alt="logo" /> {transferTo}
  //               </span>
  //             </div>
  //           )}
  //           <Button type="primary" shape="round" onClick={onClose}>
  //             Close
  //           </Button>
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      {/* <Modal
        visible={visible}
        onCancel={onClose}
        width="100%"
        footer={null}
        maskClosable={false}
        className={s.container}
      >
        {isSuccess ? renderSuccess() : renderConfirm()}
      </Modal>
      <Modal
        visible={visibleConvertProduct}
        width="100%"
        footer={null}
        maskClosable={false}
        closable={false}
        className={s.container}
      >
        <div className={s.content_convert_product}>
          <h3>Convert to other Investment Products</h3>
          <div className={s.vusdc}>
            <span>
              <img src={productsLogo.VUSDC} alt="logo" />
              VUSDC
            </span>
            <span>Stablecoin Staking</span>
          </div>
          <p>
            With VUSDC, we convert your {order?.product} into USDC, which then
            earns up to 10% APY.
          </p>
          <a
            href="https://vegaxholdings.medium.com/guide-central-vusdc-34e9bc75487d"
            target="_blank"
            rel="noreferrer"
          >
            More details about VegaX USDC Yield Product
          </a>
          <Button type="primary" shape="round" block onClick={onConvertClick}>
            Yes, convert funds
          </Button>
          <Button
            type="primary"
            shape="round"
            ghost
            block
            onClick={() => {
              setVisibleConvertProduct(false);
              setDoesNotWantToConvert(true);
            }}
          >
            No, I want to sell
          </Button>
        </div>
      </Modal> */}
    </>
  );
};

export default ConfirmTxModal;
