import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectProductsLogo } from '../../../Products/selectors';
import { makeSelectReferralCode } from '../../../User/selectors';
import {
  formatCurrencyNumber,
  formatNumber,
  getTimeFromToday,
} from '../../../../utils/format';
import { getCurrencyIcon } from '../../../../services/coinmarketcap';
import { routes } from '../../../../config/routes';
import { Notification } from '../../types';
import { formatCurrencyAmount, formatText } from './util';

import s from './NotificationBox.module.less';
import defaultIcon from '../../../../assets/images/default-token-logo.svg';
import giftIcon from '../../../../assets/icons/notifications/gift.svg';
import identityIcon from '../../../../assets/icons/notifications/identity.svg';
import withdrawIcon from '../../../../assets/icons/notifications/withdraw.svg';
import referralIcon from '../../../../assets/icons/notifications/referral.svg';

type Props = {
  data: Notification[];
  amount: number;
  setVisible: (visible: boolean) => void;
};

const NotificationBox = ({ data, amount, setVisible }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const productsLogo = useAppSelector(makeSelectProductsLogo());
  const referralCode = useAppSelector(makeSelectReferralCode());

  const onClickNotice = (notice: Notification) => {
    if (!notice.isSeen) {
      dispatch({
        type: 'WS_MESSAGE_SEND',
        payload: { method: 'seen', params: { notification_id: notice.id } },
      });
    }
    switch (notice.type) {
      case 'transaction':
        history.push(routes.transactions.path);
        break;
      case 'referral':
        history.push(routes.rewards.path);
        break;
      case 'my_account':
        history.push(routes.profile.path);
        break;
      default:
        break;
    }
    setVisible(false);
  };

  const onReadAll = () => {
    if (amount) {
      dispatch({
        type: 'WS_MESSAGE_SEND',
        payload: { method: 'readAll' },
      });
    }
  };

  const renderIcon = useCallback(
    (notice: Notification) => {
      switch (notice.slug) {
        case 'order_sell_pending':
        case 'order_sell_completed':
        case 'order_buy_pending':
        case 'order_buy_completed':
        case 'order_transfer_buy_completed':
          return notice.parameters.symbol
            ? productsLogo[notice.parameters.symbol]
            : defaultIcon;
        case 'order_transfer_sell_pending':
          return notice.parameters.relatedOrder?.symbol
            ? productsLogo[notice.parameters.relatedOrder.symbol]
            : defaultIcon;
        case 'transaction_deposit_pending':
        case 'transaction_deposit_completed':
          return notice.parameters.currency
            ? getCurrencyIcon(notice.parameters.currency)
            : defaultIcon;
        case 'transaction_withdrawal_pending':
        case 'transaction_withdrawal_completed':
          return withdrawIcon;
        case 'referral_user_joined':
        case 'referral_user_made_investment':
          return referralIcon;
        case 'referral_joined_bonus':
        case 'referral_investment_bonus':
          return giftIcon;
        case 'documents_verified_pending':
        case 'documents_verified_verified':
        case 'documents_verified_declined':
          return identityIcon;
        default:
          return defaultIcon;
      }
    },
    [productsLogo]
  );

  const renderMessage = useCallback(
    (notice: Notification) => {
      const values: Record<string, any> = {};

      switch (notice.slug) {
        case 'order_sell_pending':
        case 'order_sell_completed':
        case 'order_buy_pending':
        case 'order_buy_completed':
        case 'transaction_deposit_completed':
        case 'transaction_withdrawal_completed':
          values.amount = formatNumber(
            Math.floor(notice.parameters.amount || 0)
          );
          values.transactionAmount = formatCurrencyAmount(
            notice.parameters.transactionAmount
          );
          values.symbol = notice.parameters.symbol;
          values.currency = notice.parameters.currency;
          values.walletAddress = notice.parameters.walletAddress;
          break;
        case 'order_transfer_sell_pending':
          values.amount = formatNumber(notice.parameters.amount || 0);
          values.symbol = notice.parameters.symbol;
          values.currency = notice.parameters.relatedOrder?.symbol;
          break;
        case 'order_transfer_buy_completed':
          values.amount = formatNumber(
            Number(notice.parameters?.relatedOrder?.amount)
          );
          values.symbol = notice.parameters.relatedOrder?.symbol;
          values.currency = notice.parameters.symbol;
          values.transactionAmount = formatNumber(
            Math.floor(notice.parameters.amount || 0)
          );
          break;
        case 'referral_user_joined':
        case 'referral_user_made_investment':
        case 'referral_joined_bonus':
        case 'referral_investment_bonus':
          values.bonus = formatCurrencyNumber(
            Number(notice.parameters.bonus),
            false
          );
          values.referralCode = referralCode;
          break;
        default:
          break;
      }

      return formatText(notice.slug, values);
    },
    [referralCode]
  );

  return (
    <div className={s.container}>
      <div className={s.container_header}>
        <button type="button" onClick={onReadAll}>
          Mark all as read
        </button>
      </div>
      <div className={s.list}>
        {data.map((item) => (
          <div
            key={item.id}
            className={cx(s.item, !item.isSeen && s.item_new)}
            onClick={() => {
              onClickNotice(item);
            }}
            role="presentation"
          >
            <img src={renderIcon(item)} alt="icon" />
            <div>
              {renderMessage(item)}
              <p className={s.item_date}>{getTimeFromToday(item.createdAt)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationBox;
