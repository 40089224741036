import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import pkg from '../../../package.json';

const dsn = process.env.REACT_APP_SENTRY_DSN;

const initializeSentry = () => {
  if (dsn) {
    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      release: pkg.version,
    });
  }
};

export default initializeSentry;
