import { formatCurrencyNumber, formatNumber } from './format';
import {
  IndexChartData,
  IndexChartResponse,
  StrategyChartData,
  StrategyChartResponse,
} from '../containers/Products/types';
import { ChartData } from '../components/Chart/types';
import { RewardsChartData } from '../containers/ReferralsRewards/types';

const formatTimestamp = (timestamp: number) => timestamp * 1000;

export const formatRewardsChartData = (
  data: RewardsChartData
): RewardsChartData =>
  data.map((item) => ({ ...item, date: formatTimestamp(item.date) }));

export const formatStrategyChartData = (data: StrategyChartResponse) => {
  let result: StrategyChartData = [];
  if (data.labels.length === data.dataset.length) {
    result = data.labels.map((date, i) => ({
      date: formatTimestamp(date),
      value: data.dataset[i],
    }));
    return result;
  }
  return result;
};

export const formatIndexChartData = (data: IndexChartResponse) => {
  let result: IndexChartData = [];
  if (data.labels.length === data.dataset.length) {
    result = data.labels.map((date, i) => ({
      date: formatTimestamp(date),
      value: data.dataset[i],
    }));
    return result;
  }
  return result;
};

export const getMinMaxValueChartData = (data: ChartData) => {
  let min = data[0].value;
  let max = data[0].value;

  for (let i = 1; i < data.length; i++) {
    const { value } = data[i];
    min = value < min ? value : min;
    max = value > max ? value : max;
  }

  return [min, max];
};

export const getKeyStatsFromChart = (data: ChartData) => {
  const periodInDays = [7, 30, 90];

  return periodInDays.map((days) => {
    if (data.length > days) {
      const slice = data.slice(data.length - days);
      const [min, max] = getMinMaxValueChartData(slice);

      return `${formatNumber(min, 1)} - ${formatNumber(max, 1)}`;
    }

    return formatNumber(0, 0);
  });
};

export const getYearsFormRewardsChart = (chartData: RewardsChartData) => {
  const years: string[] = [];
  if (chartData.length) {
    const lastYear = new Date(chartData[0].date).getFullYear();
    const currentYear = new Date(Date.now()).getFullYear();

    for (let i = lastYear; i <= currentYear; i++) {
      years.push(i.toString());
    }
    return years.reverse();
  }
  return years;
};
