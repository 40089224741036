import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';

import OtpInput from '../../../../components/OtpInput';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectGoogle2FAModal } from '../../../ModalProvider/selectors';
import { modalActions } from '../../../ModalProvider/slice';

import s from './Google2FAModal.module.less';

import logoImg from '../../../../assets/images/google-auth.svg';

const NUM_INPUTS = 6;

const Google2FAModal = () => {
  const dispatch = useAppDispatch();
  const { visible, callback } = useAppSelector(makeSelectGoogle2FAModal());
  const [otpValue, setOtpValue] = useState('');
  const [loading, setLoading] = useState(false);
  const disabled = otpValue.length !== NUM_INPUTS;

  const onClose = () => {
    setOtpValue('');
    dispatch(
      modalActions.open({ name: 'goggle2FA', data: { visible: false } })
    );
  };

  const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!disabled && callback) {
      setLoading(true);
      callback(otpValue)
        .then(() => {
          onClose();
        })
        .catch((err) => {
          message.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width="100%"
      className={s.container}
      destroyOnClose
    >
      <form className={s.content}>
        <img src={logoImg} alt="logo" />
        <h3>Input Security Authentication</h3>
        <p>
          Input the 6-digits code provided by{' '}
          <strong>Google Authenticator</strong>
        </p>
        <OtpInput
          value={otpValue}
          onChange={(value) => setOtpValue(value)}
          numInputs={NUM_INPUTS}
        />
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          disabled={disabled}
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default Google2FAModal;
