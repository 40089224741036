import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import initializeSentry from './services/analytics/sentry';
import initializeHotjar from './services/analytics/hotjar';
import { initializeGTM } from './services/analytics/gtm';
import { initKakao } from './services/kakao';

import './theme/main.less';

// Sentry
initializeSentry();

// Kakao
initKakao();

// Google Tag Manager
initializeGTM();

// Hotjar Tracking Code
initializeHotjar();

ReactDOM.render(<App />, document.getElementById('root'));
export { isVusdcOrVdarc } from './utils/validate';
