import { combineReducers } from '@reduxjs/toolkit';

import { productsReducer } from '../containers/Products/slice';
import { modalReducer } from '../containers/ModalProvider/slice';
import { fundsReducer } from '../containers/Funds/slice';
import { currenciesReducer } from '../containers/Currencies/slice';
import { portfolioReducer } from '../containers/Portfolio/slice';
import { userReducer } from '../containers/User/slice';
import { transactionsReducer } from '../containers/Transactions/slice';
import { rewardsReducer } from '../containers/ReferralsRewards/slice';
import { gideCentralReducer } from '../containers/GuideCentral/slice';
import { notificationsReducer } from '../containers/Notifications/slice';

const reducers = {
  user: userReducer,
  products: productsReducer,
  modal: modalReducer,
  funds: fundsReducer,
  currencies: currenciesReducer,
  portfolio: portfolioReducer,
  transactions: transactionsReducer,
  rewards: rewardsReducer,
  gideCentral: gideCentralReducer,
  notifications: notificationsReducer,
};

const createRootReducer = () =>
  combineReducers({
    ...reducers,
  });

export { createRootReducer };
