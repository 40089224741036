import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { Route } from '../../config/routes';
import { eventGTM } from '../../services/analytics/gtm';

import s from './Sider.module.less';

type Props = {
  item: Route;
  withStroke?: boolean;
};

const SiderItem = ({
  item: { name, path, icon: Icon = null },
  withStroke = false,
}: Props) => (
  <NavLink
    exact
    to={path}
    className={s.item}
    activeClassName={cx(s.item_active, withStroke && s.item_active_stroke)}
    isActive={(match, location) => {
      if (path === '/products' && location.pathname.startsWith('/products/')) {
        return true;
      }
      if (path === '/indexes' && location.pathname.startsWith('/indexes/')) {
        return true;
      }
      return location.pathname === path;
    }}
    onClick={() => {
      eventGTM({
        event: 'Sitewide_Click_on_Menu_Click_Text_link_in_Left_side',
      });
    }}
  >
    {Icon && <Icon />}
    <span>{name}</span>
  </NavLink>
);

export default SiderItem;
