import React, { useState } from 'react';
import { Badge, Dropdown } from 'antd';

import { useAppSelector } from '../../redux/store';
import { makeSelectAmountNewNotices, makeSelectNotices } from './selectors';
import NotificationBox from './components/NotificationBox';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';

import s from './NotificationsView.module.less';

const NotificationsView = () => {
  const notices = useAppSelector(makeSelectNotices());
  const amount = useAppSelector(makeSelectAmountNewNotices());
  const [visible, setVisible] = useState(false);

  const trigger = (
    <div className={s.wrap}>
      <Badge count={amount} offset={[6, 0]} size="small">
        <BellIcon />
      </Badge>
    </div>
  );

  if (!notices.length) {
    return trigger;
  }

  const overlay = (
    <NotificationBox data={notices} amount={amount} setVisible={setVisible} />
  );

  return (
    <Dropdown
      overlay={overlay}
      trigger={['click']}
      placement="bottomRight"
      visible={visible}
      onVisibleChange={setVisible}
      // @ts-ignore
      destroyPopupOnHide
    >
      {trigger}
    </Dropdown>
  );
};

export default NotificationsView;
