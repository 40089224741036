import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortfolioState, PortfolioProduct } from './types';
import { ChartData } from '../../components/Chart/types';

export const initialState: PortfolioState = {
  data: [],
  chart: [],
  loading: true,
  error: null,
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loading = true;
    },
    fetchSuccess: (state, action: PayloadAction<PortfolioProduct[]>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    buy: () => {
      // const index = state.data.findIndex(
      //   (item) => item.symbol === action.payload.symbol
      // );
      // if (index === -1) {
      //   state.data.push(action.payload);
      // } else {
      //   state.data[index].amount += action.payload.amount;
      //   state.data[index].initialValue = action.payload.initialValue;
      //   state.data[index].created = action.payload.created;
      // }
    },
    sell: () => {
      // const index = state.data.findIndex(
      //   (item) => item.symbol === action.payload.symbol
      // );
      // if (index !== -1) {
      //   state.data[index].amount -= action.payload.amount;
      //   state.data[index].initialValue = action.payload.initialValue;
      //   state.data[index].created = action.payload.created;
      //
      //   if (state.data[index].amount <= 0) {
      //     state.data.splice(index, 1);
      //   }
      // }
    },
    fetchChart: () => {},
    fetchChartSuccess: (state, action: PayloadAction<ChartData>) => {
      state.chart = action.payload;
    },
  },
});

export const portfolioActions = portfolioSlice.actions;
export const portfolioReducer = portfolioSlice.reducer;
