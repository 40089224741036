import React, { useState, useEffect } from 'react';
import { Layout, Drawer } from 'antd';
import { useLocation } from 'react-router-dom';

import { useMediaQueryContext } from '../../contexts/MediaQueryProvider';
import Trigger from './Trigger';
import SiderItem from './SiderItem';
import ReferralCode from '../ReferralCode';
import { routes } from '../../config/routes';

import s from './Sider.module.less';

import logo from '../../assets/images/vegax-logo.svg';
import referralImg from '../../assets/images/invite-friends.svg';

const Sider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { isXl } = useMediaQueryContext();
  const location = useLocation();

  useEffect(() => {
    if (!isXl && collapsed) {
      setCollapsed(false);
    }
  }, [location, isXl]);

  const content = (
    <div className={s.content}>
      <header>
        <img src={logo} alt="VegaX" className={s.logo} />
        <Trigger onClick={() => setCollapsed((prevState) => !prevState)} />
      </header>
      <nav>
        <SiderItem item={routes.overview} />
        <SiderItem item={routes.transactions} />
        <SiderItem item={routes.depositBonus} />

        <div className={s.category_title}>Investments</div>

        <SiderItem item={routes.myPortfolio} />
        <SiderItem item={routes.products} withStroke />
        {/* <SiderItem item={routes.indexes} /> */}

        {/* <div className={s.category_title}>Member Benefits</div>

        <SiderItem item={routes.rewards} /> */}
      </nav>
      {/* {(!collapsed || !isXl) && (
        <div className={s.referral}>
          <img src={referralImg} alt="" />
          <span>
            Invite your friends & earn <strong>$15</strong> each!
          </span>
          <ReferralCode />
        </div>
      )} */}
    </div>
  );

  if (!isXl) {
    return (
      <>
        <div className={s.mobile_trigger}>
          <Trigger onClick={() => setCollapsed(true)} />
        </div>

        <Drawer
          visible={collapsed}
          placement="left"
          closable={false}
          maskClosable
          width={265}
          bodyStyle={{ overflow: 'inherit' }}
          onClose={() => setCollapsed(false)}
        >
          {content}
        </Drawer>
      </>
    );
  }

  return (
    <Layout.Sider
      width={243}
      collapsedWidth={96}
      collapsed={collapsed}
      collapsible
      trigger={null}
      style={{ zIndex: 2 }}
    >
      {content}
    </Layout.Sider>
  );
};

export default Sider;
