// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OtpInput-module__container--1OVT0 {\n  margin-top: 30px;\n}\n.OtpInput-module__container--1OVT0 > div:not(.OtpInput-module__container--1OVT0 > div:last-child) {\n  margin-right: 10px;\n}\n.OtpInput-module__input--1t8VF {\n  width: 40px !important;\n  height: 40px;\n  border-color: #DEDEDE;\n  border-width: 0 0 1px 0;\n  font-size: 28px;\n  font-weight: 300;\n}\n", "",{"version":3,"sources":["webpack://src/components/OtpInput/OtpInput.module.less"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;AAGI;EACE,kBAAA;AADN;AAMA;EACE,sBAAA;EACA,YAAA;EACA,qBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAJF","sourcesContent":["\n.container {\n  margin-top: 30px;\n\n  & > div {\n    &:not(&:last-child) {\n      margin-right: 10px;\n    }\n  }\n}\n\n.input {\n  width: 40px !important;\n  height: 40px;\n  border-color: #DEDEDE;\n  border-width: 0 0 1px 0;\n  font-size: 28px;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OtpInput-module__container--1OVT0",
	"input": "OtpInput-module__input--1t8VF"
};
export default ___CSS_LOADER_EXPORT___;
