import React, { useEffect } from 'react';
import { Layout as LayoutAntd } from 'antd';
import { useLocation } from 'react-router-dom';

import Sider from '../Sider';
import Header from '../Header';
import Footer from '../Footer';
import s from './Layout.module.less';

const { Content } = LayoutAntd;

const Layout: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.startsWith('/products')) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <LayoutAntd className={s.app}>
      <Sider />
      <LayoutAntd>
        <Header />
        <Content className={s.app_content}>{children}</Content>
        <Footer />
      </LayoutAntd>
    </LayoutAntd>
  );
};

export default Layout;
