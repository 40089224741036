import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import UserMenu from '../UserMenu';
import { routes, getRouteName } from '../../config/routes';
import s from './Header.module.less';
import logo from '../../assets/images/vegax-logo.svg';

const Header = () => {
  let { pathname } = useLocation();
  if (pathname.startsWith('/indexes/')) {
    pathname = routes.currentIndex.path;
  }

  const title = getRouteName(pathname);

  return (
    <>
      <Helmet>
        <title>{`${!!title && `${title} | `} VegaX Dashboard`}</title>
      </Helmet>
      <header className={s.header}>
        <h2>
          <span className={s.title}>
            {!!title && title}
            <span className={s.divider} />
          </span>
        </h2>
        <Link to={routes.overview.path}>
          <img src={logo} alt="VegaX" className={s.logo} />
        </Link>
        <UserMenu />
      </header>
    </>
  );
};

export default Header;
