import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Input, Select } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectAddFundsModal } from '../../../ModalProvider/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import QRCode from '../../../../components/QRCode';
import CopyButton from '../../../../components/CopyButton';

import { makeSelectFunds } from '../../selectors';
import { FundName } from '../../types';
import BonusPopup from '../../../../assets/images/bonus-popup.png';

import s from './DepositModal.module.less';
import { makeSelectIsPromotionUser } from '../../../User/selectors';

const { Option } = Select;

const DepositModal = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const { visible, fund } = useAppSelector(makeSelectAddFundsModal());
  const funds = useAppSelector(makeSelectFunds());
  const [currentFund, setCurrentFund] = useState<FundName>('BTC');
  const isPromotionUser = useAppSelector(makeSelectIsPromotionUser());

  useEffect(() => {
    if (fund) {
      setCurrentFund(fund);
    }
  }, [fund]);

  const onClose = () => {
    dispatch(modalActions.open({ name: 'addFunds', data: { visible: false } }));
    setCurrentFund('BTC');
  };

  const address = useMemo(
    () =>
      funds[currentFund].wallet ? (
        <div className={s.way}>
          <QRCode text={funds[currentFund].wallet} width={186} />

          <div className={s.inputs}>
            <div>
              <p className={s.inputLabel}>Select the asset type</p>
              <Select
                size="large"
                className={s.select}
                defaultValue={currentFund}
                onSelect={(value) => setCurrentFund(value)}
              >
                {Object.values(funds).map((item) => (
                  <Option key={item.symbol} value={item.symbol}>
                    <div className={s.select_item}>
                      <img src={item.icon} alt={item.symbol} />
                      <span>{item.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <p className={s.inputLabel}>Address</p>
              <Input
                size="large"
                style={{ height: '58px', borderRadius: '200px' }}
                ref={inputRef}
                value={funds[currentFund].wallet}
                readOnly
                suffix={<CopyButton elementRef={inputRef} />}
              />
            </div>
          </div>
        </div>
      ) : null,
    [currentFund, funds]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className={s.container}
      width="100%"
      // title="Add Deposit"
      destroyOnClose
    >
      <div style={{ fontSize: '20px', fontWeight: 700 }}>Add Deposit</div>
      {isPromotionUser && (
        <img
          src={BonusPopup}
          alt="BonusPopup"
          width="full"
          height="auto"
          style={{
            display: 'block',
            maxWidth: '100%',
          }}
        />
      )}

      {address}
      <p className={s.descr}>
        You will receive email notifications regarding the status of your
        deposit. Please note that deposits may take up to 24-48 hours to appear
        in your account based on network congestion.
      </p>
      <p className={s.contact}>
        <strong>Interested in a fiat to OTC transaction?</strong> Contact us at{' '}
        <a href="mailto:clients@vegaxholdings.com">clients@vegaxholdings.com</a>{' '}
        to learn more
      </p>
    </Modal>
  );
};

export default DepositModal;
