import React, { useEffect, useRef, memo } from 'react';
import qrcode from 'qrcode';

type Props = {
  text: string;
  width?: number;
};

const QRCode = ({ text, width = 150 }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (text) {
      qrcode.toCanvas(ref.current, text, { width, margin: 2 });
    }
  }, [text]);

  return <canvas ref={ref} />;
};

export default memo(QRCode);
