import data from '../assets/top500Coinmarketcap.json';
import defaultLogo from '../assets/images/default-token-logo.svg';

const coinmarketcapUrl = 'https://coinmarketcap.com/currencies/';
const coinmarketcapIconUrl =
  'https://s2.coinmarketcap.com/static/img/coins/64x64/';

type CoinmarketcapCurrency = {
  symbol: string;
  name: string;
  rank: number;
  id: number;
  slug: string;
  status: string;
  icon: string;
  url: string;
};

export const getCoinmarketcapCurrency = (
  symbol: string
): CoinmarketcapCurrency => {
  const symbolUpperCase = symbol.toUpperCase();
  const coin = data.find((item) => item.symbol === symbolUpperCase);
  if (coin) {
    return {
      ...coin,
      icon: `${coinmarketcapIconUrl}${coin.id}.png`,
      url: `${coinmarketcapUrl}${coin.slug}`,
    };
  }
  return {
    symbol: symbolUpperCase,
    name: symbolUpperCase,
    rank: 0,
    id: 0,
    slug: '',
    status: '',
    icon: defaultLogo,
    url: '',
  };
};

export const getCurrencyIcon = (symbol: string) => {
  const { icon } = getCoinmarketcapCurrency(symbol);
  return icon;
};
