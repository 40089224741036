import React from 'react';

import { routes } from '../../config/routes';

import s from './Footer.module.less';

const links = [routes.home, routes.privacyPolicy, routes.help];
const today = new Date();
const year = today.getFullYear();

const Footer = () => (
  <footer className={s.footer}>
    <div className={s.main}>
      <ul>
        {links.map(({ name, path }) => (
          <li key={name}>
            <a href={path} target="_blank" rel="noreferrer">
              {name}
            </a>
          </li>
        ))}
      </ul>
      <span>{year} &copy; VegaX Holdings</span>
    </div>
  </footer>
);

export default Footer;
