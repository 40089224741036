import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';

import OtpInput from '../../../../components/OtpInput';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectOtpModal } from '../../../ModalProvider/selectors';

import s from './OtpModal.module.less';
import { modalActions } from '../../../ModalProvider/slice';
import mainApiProtected from '../../../../services/mainApiProtected';

const NUM_INPUTS = 6;

const OtpModal = () => {
  const dispatch = useAppDispatch();
  const { visible, callback } = useAppSelector(makeSelectOtpModal());
  const [otpValue, setOtpValue] = useState('');
  const [loading, setLoading] = useState(false);
  const disabled = otpValue.length !== NUM_INPUTS;

  const onClose = () => {
    setOtpValue('');
    dispatch(modalActions.open({ name: 'OTP', data: { visible: false } }));
  };

  const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (otpValue.length === NUM_INPUTS && callback) {
      callback(otpValue)
        .then(() => {
          onClose();
        })
        .catch((err) => {
          message.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onResend = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    mainApiProtected
      .sendOTPCode()
      .then(() => {
        setOtpValue('');
        message.success('Code resent');
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width="100%"
      className={s.container}
      destroyOnClose
    >
      <form className={s.content}>
        <h2>OTP Verification</h2>
        <p>Input the six digit code that has been sent to your phone number.</p>
        <OtpInput
          value={otpValue}
          onChange={(value) => setOtpValue(value)}
          numInputs={NUM_INPUTS}
        />
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          disabled={disabled}
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button>
        <a href="/" onClick={onResend}>
          Didn't receive the code?
        </a>
      </form>
    </Modal>
  );
};

export default OtpModal;
