import React from 'react';
import { Button, Modal } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';

import { modalActions } from '../../../ModalProvider/slice';

import s from './ConfirmationModal.module.less';
import { makeConfirmation } from '../../../ModalProvider/selectors';
import { makeSelectProductsLogo } from '../../selectors';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-filled.svg';

const ConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const { visible, type, reqSummary } = useAppSelector(makeConfirmation());
  const productsLogo = useAppSelector(makeSelectProductsLogo());

  if (!reqSummary) return null;

  const onClose = () => {
    dispatch(
      modalActions.open({ name: 'confirmation', data: { visible: false } })
    );
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      width="100%"
      bodyStyle={{ padding: 0 }}
      onCancel={onClose}
      destroyOnClose
    >
      <div className={s.container}>
        <CheckIcon />
        <p className={s.title}>
          {type === 'SELL' ? 'Selling' : 'Buying'} request sent!
        </p>
        <div className={s.summary}>
          <div className={s.product_item}>
            <img
              src={productsLogo[reqSummary.productSymbol] || ''}
              alt={reqSummary.productSymbol}
            />
            <span>{reqSummary.productSymbol}</span>
          </div>
          <div className={s.currency_item}>
            {type === 'SELL' ? null : (
              <img
                src={reqSummary.currencySymbol || ''}
                alt={reqSummary.currencySymbol}
              />
            )}

            <span>{reqSummary.amount}</span>
          </div>
        </div>
        <Button
          size="large"
          className={s.button}
          onClick={onClose}
          shape="round"
          block
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
