import React, { useState } from 'react';
import { Button, Checkbox, Input, Modal, Select } from 'antd';
import cx from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectSubscriptionProducts } from '../../../ModalProvider/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import attentionImg from '../../../../assets/icons/attention-2.svg';

import s from './SubscriptionProductModal.module.less';
import {
  makeSelectEmail,
  makeSelectUserId,
  makeSelectUserName,
} from '../../../User/selectors';
import { makeSelectFunds2 } from '../../../Funds/selectors';
import { makeSelectProductsByCategory } from '../../selectors';
import mainApiProtected from '../../../../services/mainApiProtected';

const SubscriptionProductModal = () => {
  const dispatch = useAppDispatch();
  const { visible, productSymbol, icon } = useAppSelector(
    makeSelectSubscriptionProducts()
  );
  const userId = useAppSelector(makeSelectUserId());
  const userName = useAppSelector(makeSelectUserName());
  const userEmail = useAppSelector(makeSelectEmail());
  const [duration, setDuration] = useState('30 days');
  const [amount, setAmount] = useState('');
  const [checked, setChecked] = useState(false);
  const funds = useAppSelector(makeSelectFunds2());

  const balance = funds.find((item) => item.symbol === productSymbol)?.amount;
  const subscriptionProducts = useAppSelector(makeSelectProductsByCategory(6));
  const selectedProducts = subscriptionProducts.filter(
    (item) => item.baseCurrency === productSymbol
  );
  const selectedProduct = selectedProducts.find(
    (el) => el.symbol.split('_')[2] === String(duration).split(' ')[0]
  );

  const onClose = () => {
    dispatch(
      modalActions.open({
        name: 'subscriptionProducts',
        data: { visible: false },
      })
    );
  };

  const handleClick = async () => {
    try {
      await mainApiProtected.orderBuy({
        user_id: userId,
        product_name: selectedProduct?.symbol ?? '',
        currency_amount: Number(amount),
        currency: productSymbol ?? '',
      });

      dispatch(modalActions.closeAll());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className={s.wrapper}
      closable={false}
      destroyOnClose
    >
      <div className={cx(s.container, s.light)}>
        <div style={{ width: '100%', height: '1px' }} />
        <div className={s.content}>
          <div className={s.headerContainer}>
            <span className={s.header}>Subscription Request</span>
          </div>
          <div className={s.hstack}>
            <div className={s.label}>Asset</div>
            <div
              style={{
                display: 'flex',
                fontWeight: 700,
                fontSize: '16px',
                gap: '8px',
              }}
            >
              <img
                src={icon}
                alt="icon"
                style={{ width: '24px', height: '24px' }}
              />{' '}
              {productSymbol}
            </div>
          </div>
          <div className={s.hstack}>
            <div className={s.label}>Select your duration</div>
            <Select
              size="large"
              className={s.select}
              value={duration}
              onChange={(e) => setDuration(e)}
            >
              {selectedProducts.map((el: any) => (
                <Select.Option
                  value={`${el.symbol.split('_')[2]} days`}
                  key={el.symbol}
                >
                  {el.percent && (
                    <div className={s.select_item}>
                      {el.symbol.split('_')[2]} days
                    </div>
                  )}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className={s.vstack}>
            <div className={s.hstack}>
              <div className={s.label}>Target APY</div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '16px',
                }}
              >
                {selectedProduct?.percent &&
                  +(selectedProduct?.percent * 100).toFixed(2)}
                %
              </div>
            </div>
            <div className={s.info_box}>
              Upon expiry, the current balance will be automatically be locked
              up to receive interest for the same duration
            </div>
          </div>
          <div className={s.vstack}>
            <div className={s.hstack}>
              <div className={s.label}>Amount</div>
              <div>Avail. Balance : {balance}</div>
            </div>
            <Input
              size="large"
              className={s.input}
              placeholder="Enter amount"
              bordered={true}
              style={{
                borderRadius: '15px',
                color: 'black',
                height: '50px',
                alignItems: 'center',
              }}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              prefix={
                <img
                  src={icon}
                  alt="icon"
                  style={{ width: '24px', height: '24px' }}
                />
              }
              suffix={
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => setAmount(String(balance))}
                >
                  Max
                </Button>
              }
            />
          </div>

          <div
            className={s.hstack}
            style={{ alignItems: 'flex-start', gap: '15px' }}
          >
            <Checkbox onChange={(e) => setChecked(e.target.checked)} />
            <div>
              I understand that I will be locking up my cryptocurrency for the
              selected period of time and the returns displayed are annual
              targets.
            </div>
          </div>

          {(!balance || balance <= 0) && (
            <div className={s.warning_box}>
              <img
                style={{ width: '34px', height: '34px' }}
                src={attentionImg}
                alt="attention"
              />
              <span>
                Your account currently doesn't have any available balance.
                Please start by{' '}
                <span
                  role="presentation"
                  className={s.deposit_link}
                  onClick={() => {
                    dispatch(
                      modalActions.open({
                        name: 'subscriptionProducts',
                        data: { visible: false },
                      })
                    );
                    dispatch(
                      modalActions.open({
                        name: 'addFunds',
                        data: { visible: true },
                      })
                    );
                  }}
                >
                  depositing assets
                </span>{' '}
                into your wallet.
              </span>
            </div>
          )}

          <Button
            className={s.submit_button}
            size="large"
            shape="round"
            block
            disabled={
              !balance ||
              balance <= 0 ||
              !amount ||
              !checked ||
              +amount > +balance
            }
            onClick={handleClick}
          >
            Send Request
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionProductModal;
