import React from 'react';

import s from './Sider.module.less';

const Trigger = (props: { onClick: () => void }) => (
  <button className={s.trigger} type="button" onClick={props.onClick}>
    <span />
    <span />
    <span />
  </button>
);

export default Trigger;
