import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import s from './GoogleTranslate.module.less';

declare global {
  interface Window {
    googleTranslateElementInit?: () => void;
    google: any;
  }
}

const GoogleTranslate = () => {
  const [cookies, setCookie] = useCookies(['googtrans']);
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  useEffect(() => {
    const existingCookie = cookies.googtrans;

    let targetLanguage = 'en';

    if (existingCookie) {
      const parts = existingCookie.split('/');
      targetLanguage = parts.length === 3 ? parts[2] : 'en';
    } else {
      const browserLanguage = navigator.language;
      targetLanguage = browserLanguage === 'ko' ? 'ko' : 'en';

      const cookieValue = `/en/${targetLanguage}`;
      setCookie('googtrans', cookieValue, { path: '/' });
      setCookie('googtrans', cookieValue, {
        path: '/',
        domain: window.location.hostname,
      });
    }

    const script = document.createElement('script');
    script.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    script.id = 'google-translate-script';

    window.googleTranslateElementInit = () => {
      const element = document.getElementById('google_translate_element');

      if (element) {
        element.innerHTML = ''; // 중복 초기화 방지
      }
      // eslint-disable-next-line no-new
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,ko',
        },
        'google_translate_element'
      );
    };

    if (targetLanguage !== 'en') {
      setTimeout(() => {
        const selectField = document.querySelector('.goog-te-combo');

        if (selectField) {
          (selectField as HTMLSelectElement).value = targetLanguage;
          selectField.dispatchEvent(new Event('change'));
        }
      }, 500);
    }
    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById('google-translate-script');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <div className={`${s.LanguageToggle} ${visible ? s.visible : s.hidden}`}>
      <div id="google_translate_element" />
    </div>
  );
};

export default GoogleTranslate;
