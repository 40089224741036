import React, { createContext, useContext, useMemo } from 'react';

import useMedia from '../hooks/useMedia';

type MediaQueryName = 'isXs' | 'isSm' | 'isMd' | 'isLg' | 'isXl';

type Value = {
  [key in MediaQueryName]: boolean;
};

const mediaQueries = {
  mobile: '(max-width: 1199px)',
  xs: '(min-width: 321px)',
  sm: '(min-width: 414px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
};

const MediaQueryContext = createContext<Value>({
  isXs: false,
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: true,
});

const MediaQueryProvider: React.FC = ({ children }) => {
  const isXs = useMedia(mediaQueries.xs);
  const isSm = useMedia(mediaQueries.sm);
  const isMd = useMedia(mediaQueries.md);
  const isLg = useMedia(mediaQueries.lg);
  const isXl = useMedia(mediaQueries.xl);

  const value = useMemo(() => ({ isXs, isSm, isMd, isLg, isXl }), [
    isLg,
    isMd,
    isSm,
    isXl,
    isXs,
  ]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => useContext(MediaQueryContext);

export default MediaQueryProvider;
