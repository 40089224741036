// @ts-nocheck

const token = process.env.REACT_APP_KAKAO_KEY;

const loadSdk = () =>
  new Promise((resolve) => {
    const js = document.createElement('script');

    js.id = 'kakao-sdk';
    js.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
    js.onload = resolve;

    document.body.append(js);
  });

export const initKakao = async () => {
  await loadSdk();
  if (token && window.Kakao) {
    window.Kakao.init(token);
  }
};

export const shareLinkToKakao = (refLink: string, text: string) => {
  if (window.Kakao && window.Kakao.Link) {
    window.Kakao.Link.sendDefault({
      objectType: 'text',
      text: `${text} ${refLink}`,
      link: {
        mobileWebUrl: refLink,
        webUrl: refLink,
      },
    });
  }
};
