import TagManager from 'react-gtm-module';

type DataLayer = {
  event: string;
  eventCategory?: string;
  eventLabel?: string;
  userId?: number;
};

const gtmId = process.env.REACT_APP_GTM_ID;

export const initializeGTM = () => {
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
};

export const eventGTM = (dataLayer: DataLayer) => {
  TagManager.dataLayer({ dataLayer });
};
