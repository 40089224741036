// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopyButton-module__button--1vLHV {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n  padding: 0;\n  border: 0;\n  outline: 0;\n  background: transparent;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://src/components/CopyButton/CopyButton.module.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;EACA,UAAA;EACA,uBAAA;EACA,eAAA;AAAF","sourcesContent":["\n.button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n  padding: 0;\n  border: 0;\n  outline: 0;\n  background: transparent;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "CopyButton-module__button--1vLHV"
};
export default ___CSS_LOADER_EXPORT___;
