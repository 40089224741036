import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';
import { isVusdcOrVdarc } from '../../utils/validate';

export const selectPortfolioDomain = (state: ApplicationState) =>
  state.portfolio || initialState;

export const makeSelectPortfolioList = () =>
  createSelector(selectPortfolioDomain, (state) => state.data);

export const makeSelectPortfolioProduct = (symbol: string) =>
  createSelector(makeSelectPortfolioList(), (state) =>
    state.find((item) => item.symbol === symbol)
  );

export const makeSelectTotalInvestments = () =>
  createSelector(makeSelectPortfolioList(), (products) =>
    products.reduce((acc, item) => {
      if (isVusdcOrVdarc(item.symbol)) {
        return item.amountWithProfit * item.valueInCurrency + acc;
      }
      return item.amount * item.valueInCurrency + acc;
    }, 0)
  );

export const makeSelectTotalInitialInvestments = () =>
  createSelector(makeSelectPortfolioList(), (products) =>
    products.reduce(
      (acc, item) => item.amount * item.initialValueCurrency + acc,
      0
    )
  );

export const makeSelectPortfolioLoading = () =>
  createSelector(selectPortfolioDomain, (state) => state.loading);

export const makeSelectPortfolioChart = () =>
  createSelector(selectPortfolioDomain, (state) => state.chart);
