import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationsState, Notification } from './types';

export const initialState: NotificationsState = {
  data: [],
  amount: 0,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.data = action.payload.reverse();
      let amount = 0;
      for (const item of state.data) {
        if (!item.isSeen) {
          amount += 1;
        }
      }
      state.amount = amount;
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.data.splice(0, 0, action.payload);
      state.amount += 1;
    },
    seenNotification: (state, acttion: PayloadAction<Notification>) => {
      const index = state.data.findIndex(
        (item) => item.id === acttion.payload.id
      );
      if (index !== -1) {
        state.data[index].isSeen = 1;
        state.amount -= 1;
      }
    },
    readAll: (state) => {
      state.data = state.data.map((item) => ({ ...item, isSeen: 1 }));
      state.amount = 0;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
