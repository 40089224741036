import React from 'react';
import { Modal } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TradeProducts from '../TradeProducts';
import { makeSelectTradeProductsModal } from '../../../ModalProvider/selectors';
import { modalActions } from '../../../ModalProvider/slice';

import s from './TradeProductsModal.module.less';

const TradeProductsModal = () => {
  const dispatch = useAppDispatch();
  const {
    visible,
    orderType,
    productSymbol,
    isCurrentProduct,
    noSell,
  } = useAppSelector(makeSelectTradeProductsModal());

  const onClose = () => {
    dispatch(
      modalActions.open({ name: 'tradeProducts', data: { visible: false } })
    );
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      width="100%"
      className={s.container}
      onCancel={onClose}
      destroyOnClose
    >
      <TradeProducts
        theme="light"
        orderType={orderType}
        productSymbol={productSymbol}
        isCurrentProduct={isCurrentProduct}
        noSell={noSell}
      />
    </Modal>
  );
};

export default TradeProductsModal;
