import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FundsState, UserBalance, UserWallets } from './types';
import { currenciesActions } from '../Currencies/slice';
import { Currency } from '../Currencies/types';
import { getCurrencyIcon } from '../../services/coinmarketcap';

export const initialState: FundsState = {
  loading: false,
  funds: {
    BTC: {
      icon: getCurrencyIcon('BTC'),
      name: 'Bitcoin',
      symbol: 'BTC',
      wallet: '',
      amount: 0,
      price: 0,
      priceChanges: 0,
    },
    ETH: {
      icon: getCurrencyIcon('ETH'),
      name: 'Ethereum',
      symbol: 'ETH',
      wallet: '',
      amount: 0,
      price: 0,
      priceChanges: 0,
    },
    USDT: {
      icon: getCurrencyIcon('USDT'),
      name: 'USDT',
      symbol: 'USDT',
      wallet: '',
      amount: 0,
      price: 0,
      priceChanges: 0,
    },
    USDC: {
      icon: getCurrencyIcon('USDC'),
      name: 'USDC',
      symbol: 'USDC',
      wallet: '',
      amount: 0,
      price: 0,
      priceChanges: 0,
    },
  },
  bonusBalances: [],
  profit: 0,
};

const fundsSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    fetchFunds: (state) => {
      state.loading = true;
    },
    fetchFundsSuccess: (state, action: PayloadAction<UserBalance>) => {
      state.loading = false;
      state.profit = action.payload.profit;

      state.bonusBalances = action.payload.bonusBalances || [];

      action.payload.balance.forEach((fund) => {
        if (fund.currency === 'BTC') {
          state.funds.BTC.amount = fund.amount;
        }
        if (fund.currency === 'ETH') {
          state.funds.ETH.amount = fund.amount;
        }
        if (fund.currency === 'USDT') {
          state.funds.USDT.amount = fund.amount;
        }
        if (fund.currency === 'USDC') {
          state.funds.USDC.amount = fund.amount;
        }
      });
    },
    setWallets: (state, action: PayloadAction<UserWallets>) => {
      state.funds.BTC.wallet = action.payload.BTC;
      state.funds.ETH.wallet = action.payload.ETH;
      state.funds.USDT.wallet = action.payload.USDT;
      state.funds.USDC.wallet = action.payload.USDC;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      currenciesActions.fetchSuccess.type,
      (state, action: PayloadAction<Currency[]>) => {
        action.payload.forEach((currency) => {
          if (currency.id === state.funds.BTC.symbol) {
            state.funds.BTC.price = currency.price;
            state.funds.BTC.priceChanges = currency.change;
          } else if (currency.id === state.funds.ETH.symbol) {
            state.funds.ETH.price = currency.price;
            state.funds.ETH.priceChanges = currency.change;
          } else if (currency.id === state.funds.USDT.symbol) {
            state.funds.USDT.price = currency.price;
            state.funds.USDT.priceChanges = currency.change;
          } else if (currency.id === state.funds.USDC.symbol) {
            state.funds.USDC.price = currency.price;
            state.funds.USDC.priceChanges = currency.change;
          }
        });
      }
    );
  },
});

export const fundsActions = fundsSlice.actions;
export const fundsReducer = fundsSlice.reducer;
