/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Select, message } from 'antd';

import { formatNumber } from '../../../../utils/format';
import { isVusdcOrVdarc } from '../../../../utils/validate';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

import {
  makeSelectUserFromUSA,
  makeSelectUserId,
} from '../../../User/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import { Strategy } from '../../types';
import { FundType } from '../../../Funds/types';
import { FiatCurrencies } from '../../../Currencies/types';

import s from './TradeProducts.module.less';
import defaultLogo from '../../../../assets/images/default-token-logo.svg';
import './index.css';
import mainApiProtected from '../../../../services/mainApiProtected';

const { Option } = Select;

const PRODUCTS_FOR_USA = ['VEBE', 'VEEE', 'VUSDC'];

type Props = {
  theme: 'light' | 'dark';
  productSymbol?: string;
  isCurrentProduct?: boolean;
  funds: FundType[];
  products: Strategy[];
  priceBtc: number;
  fiatCurrency: FiatCurrencies;
};

const BuyProduct = ({
  isCurrentProduct,
  theme,
  productSymbol,
  funds,
  products,
  priceBtc,
  fiatCurrency,
}: Props) => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<number>(0);
  const [currentFund, setCurrentFund] = useState<FundType>(funds[0]);
  const [currentProduct, setCurrentProduct] = useState<Strategy | undefined>(
    undefined
  );
  const userFromUSA = useAppSelector(makeSelectUserFromUSA());
  const userId = useAppSelector(makeSelectUserId());

  useEffect(() => {
    if (productSymbol) {
      setCurrentProduct(products.find((p) => p.symbol === productSymbol));
    } else {
      setCurrentProduct(products[0]);
    }
  }, [products, productSymbol]);

  useEffect(() => {
    if (currentProduct) {
      const baseFund = funds.find(
        (fund) => fund.symbol === currentProduct.baseCurrency
      );
      if (baseFund) {
        setCurrentFund(baseFund);
      }
    }
  }, [currentProduct, funds]);

  const doNotBuy = useMemo(
    () =>
      userFromUSA &&
      !PRODUCTS_FOR_USA.find((p) => p === currentProduct?.symbol),
    [currentProduct?.symbol, userFromUSA]
  );

  const disabled =
    doNotBuy ||
    !currentProduct?.symbol ||
    !currentFund.symbol ||
    amount === 0 ||
    currentFund?.amount < amount;

  const handleClick = async () => {
    try {
      await mainApiProtected.orderBuy({
        user_id: userId,
        product_name: currentProduct?.symbol ?? '',
        currency_amount: amount,
        currency: currentFund.symbol,
      });

      dispatch(modalActions.closeAll());
      dispatch(
        modalActions.open({
          name: 'confirmation',
          data: {
            visible: true,
            type: 'BUY',
            reqSummary: {
              productSymbol: currentProduct?.symbol || '',
              currencySymbol: currentFund.icon,
              amount: +amount,
            },
          },
        })
      );
    } catch (e) {
      message.error(e);
    }
  };

  const btn = (
    <Button
      onClick={handleClick}
      type="primary"
      size="large"
      className={s.submit_button}
      shape="round"
      ghost={theme === 'dark'}
      block
      disabled={disabled}
    >
      Send Request
    </Button>
  );

  return (
    <>
      {!isCurrentProduct && (
        <div className={s.label}>
          <span>Select product</span>
          <Select
            size="large"
            className={s.select}
            value={currentProduct?.symbol}
            // @ts-ignore
            onChange={(value, { product }: { product: Strategy }) =>
              setCurrentProduct(product)
            }
          >
            {products.map((product) => (
              <Option
                key={product.symbol}
                value={product.symbol}
                product={product}
              >
                <div className={s.select_item}>
                  <img src={product.files.logo || defaultLogo} alt="logo" />
                  <span>{product.symbol}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
      )}
      <div className={s.label}>
        <span>Take from deposit</span>
        <Select
          size="large"
          className={s.select}
          value={currentFund.symbol}
          // @ts-ignore
          onChange={(value, { fund }: { fund: FundType }) =>
            setCurrentFund(fund)
          }
        >
          {funds.map((item) => (
            <Option
              key={item.symbol}
              value={item.symbol}
              fund={item}
              disabled={
                item.symbol !== currentProduct?.baseCurrency &&
                !isVusdcOrVdarc(currentProduct?.symbol)
              }
            >
              <div className={s.select_item}>
                <img src={item.icon} alt={item.symbol} />
                <span>
                  {formatNumber(item.amount)} {item.symbol}
                </span>
              </div>
            </Option>
          ))}
        </Select>
        <Input
          size="large"
          className={s.input}
          placeholder="Enter amount"
          addonAfter={currentFund?.symbol}
          bordered={true}
          onChange={(e) => setAmount(+e.target.value)}
        />
      </div>
      {btn}
      {currentProduct?.symbol === 'VFIX' && (
        <span style={{ marginTop: '10px' }}>
          <svg
            width="12"
            height="10"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.74562 0.21301C7.122 0.00460469 7.56334 -0.0535205 7.98085 0.0503296C8.39835 0.15418 8.76104 0.412298 8.99592 0.772749L9.0587 0.877133L14.9276 11.4736C15.0662 11.724 15.1394 12.0053 15.1404 12.2915C15.1414 12.5777 15.0702 12.8596 14.9335 13.111C14.7967 13.3624 14.5987 13.5752 14.3578 13.7298C14.117 13.8844 13.8411 13.9758 13.5555 13.9955L13.439 14H1.70267C1.41671 14.0001 1.13533 13.9282 0.884519 13.7909C0.633704 13.6535 0.421537 13.4552 0.26761 13.2142C0.113683 12.9732 0.02296 12.6973 0.00381787 12.412C-0.0153242 12.1267 0.0377321 11.8412 0.158087 11.5818L0.212548 11.4736L6.08074 0.877133C6.23564 0.597746 6.46605 0.367596 6.74562 0.21301ZM7.57085 10.2127C7.46966 10.2095 7.36885 10.2266 7.27442 10.2632C7.17999 10.2997 7.09386 10.3548 7.02114 10.4252C6.94843 10.4957 6.89061 10.58 6.85113 10.6733C6.81165 10.7665 6.7913 10.8667 6.7913 10.968C6.7913 11.0692 6.81165 11.1694 6.85113 11.2626C6.89061 11.3559 6.94843 11.4402 7.02114 11.5107C7.09386 11.5811 7.17999 11.6362 7.27442 11.6727C7.36885 11.7093 7.46966 11.7264 7.57085 11.7232C7.76703 11.717 7.95309 11.6347 8.08966 11.4938C8.22623 11.3528 8.3026 11.1642 8.3026 10.968C8.3026 10.7717 8.22623 10.5831 8.08966 10.4421C7.95309 10.3012 7.76703 10.2189 7.57085 10.2127ZM7.56934 4.15842C7.38396 4.15846 7.20504 4.22659 7.06658 4.34985C6.92811 4.47312 6.83974 4.64295 6.81823 4.82708L6.81445 4.91482V8.69836L6.81974 8.78686C6.84088 8.97135 6.92915 9.14162 7.06773 9.26524C7.20632 9.38885 7.38553 9.45716 7.57123 9.45716C7.75693 9.45716 7.93615 9.38885 8.07473 9.26524C8.21331 9.14162 8.30158 8.97135 8.32272 8.78686L8.32726 8.69836V4.91482L8.32196 4.82632C8.30027 4.6422 8.21171 4.47245 8.07311 4.34932C7.9345 4.22619 7.7555 4.15826 7.5701 4.15842H7.56934Z"
              fill="#FFCC00"
            />
          </svg>
          VFIX has minimum investment amount of $50,000
        </span>
      )}
    </>
  );
};

export default BuyProduct;
