import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Input, Select } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { makeSelectAddFundsModal } from '../../../ModalProvider/selectors';
import { modalActions } from '../../../ModalProvider/slice';
import QRCode from '../../../../components/QRCode';
import CopyButton from '../../../../components/CopyButton';
import NotVerifiedUser from '../../../User/components/NotVerifiedUser';
import { makeSelectFunds } from '../../selectors';
import { FundName } from '../../types';

import s from './AddFundsModal.module.less';

const { Option } = Select;

const AddFundsModal = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const { visible, fund } = useAppSelector(makeSelectAddFundsModal());
  const funds = useAppSelector(makeSelectFunds());
  const [currentFund, setCurrentFund] = useState<FundName>('BTC');

  useEffect(() => {
    if (fund) {
      setCurrentFund(fund);
    }
  }, [fund]);

  const onClose = () => {
    dispatch(modalActions.open({ name: 'addFunds', data: { visible: false } }));
    setCurrentFund('BTC');
  };

  const address = useMemo(
    () =>
      funds[currentFund].wallet ? (
        <>
          <div className={s.way}>
            <p>Address</p>
            <Input
              size="large"
              style={{ marginTop: '10px' }}
              ref={inputRef}
              value={funds[currentFund].wallet}
              readOnly
              suffix={<CopyButton elementRef={inputRef} />}
            />
          </div>
          <QRCode text={funds[currentFund].wallet} />
        </>
      ) : null,
    [currentFund, funds]
  );

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      className={s.container}
      width="100%"
      // title="Add Deposit"
      destroyOnClose
    >
      <div style={{ fontSize: '20px', fontWeight: 700 }}>Add Deposit</div>
      <div className={s.way}>
        <span>Select the asset type</span>
        <Select
          size="large"
          className={s.select}
          defaultValue={currentFund}
          onSelect={(value) => setCurrentFund(value)}
        >
          {Object.values(funds).map((item) => (
            <Option key={item.symbol} value={item.symbol}>
              <div className={s.select_item}>
                <img src={item.icon} alt={item.symbol} />
                <span>{item.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </div>
      {address}
      <p className={s.descr}>
        You will receive email notifications regarding the status of your
        deposit. Please note that deposits may take up to 24-48 hours to appear
        in your account based on network congestion.
      </p>
    </Modal>
  );
};

export default AddFundsModal;
