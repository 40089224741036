import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ReferralList,
  RewardList,
  RewardsChartData,
  RewardsState,
  TotalAmountReward,
  TotalRewards,
  UserLevel,
} from './types';

export const initialState: RewardsState = {
  isLoaded: false,
  currentLevel: {
    level: 'NEWCOMER',
    investmentAmount: 0,
    countReferrals: 0,
    countXpoints: 0,
    requiredInvestmentAmount: 0,
  },
  nextLevel: {
    level: 'BRONZE',
    investmentAmount: 0,
    countReferrals: 0,
    countXpoints: 0,
  },
  chart: [],
  total: {
    referral: { totalAmount: 0, amountInBtc: 0 },
    event: { totalAmount: 0, amountInBtc: 0 },
    tier: { totalAmount: 0, amountInBtc: 0 },
  },
  totalPending: { totalAmount: 0, amountInBtc: 0 },
  referralList: [],
  rewardList: [],
};

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    fetchAllData() {},
    fetchAllDataSuccess(state) {
      state.isLoaded = true;
    },
    setLevelInfo(state, action: PayloadAction<UserLevel>) {
      state.currentLevel = action.payload.currentLevel;
      state.nextLevel = { ...state.nextLevel, ...action.payload.nextLevel };
    },
    setChart(state, action: PayloadAction<RewardsChartData>) {
      state.chart = action.payload;
    },
    setTotalRewards(state, action: PayloadAction<TotalRewards>) {
      state.total = action.payload;
    },
    setRewardList(state, action: PayloadAction<RewardList>) {
      state.rewardList = action.payload;
    },
    setReferralList(state, action: PayloadAction<ReferralList>) {
      state.referralList = action.payload;
    },
    setTotalPending(state, action: PayloadAction<TotalAmountReward>) {
      state.totalPending = action.payload;
    },
  },
});

export const rewardsReducer = rewardsSlice.reducer;
export const rewardsActions = rewardsSlice.actions;
