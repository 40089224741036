import {
  all,
  fork,
  call,
  put,
  takeLatest,
  SagaReturnType,
  Effect,
  select,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { changeUserCurrency, userActions } from './slice';
import mainApiProtected from '../../services/mainApiProtected';
import { UserState } from './types';

function* fetchUserProfile() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchUserProfile> = yield call(
      mainApiProtected.fetchUserProfile
    );
    const { user }: UserState = yield select((state) => state.user);
    if (user.settings.currency !== data.settings.currency) {
      yield put(changeUserCurrency({ currency: data.settings.currency }));
      localStorage.setItem('currency', data.settings.currency);
    }
    yield put(userActions.fetchUserSuccess(data));

    Sentry.setUser({ id: data.id.toString() });
  } catch (e) {
    console.error(e);
  }
}

function* fetchNotificationSettings() {
  try {
    const {
      data,
    }: SagaReturnType<
      typeof mainApiProtected.fetchNotificationSettings
    > = yield call(mainApiProtected.fetchNotificationSettings);
    const settings = data.map(({ notification: { id } }) => id);

    yield put(userActions.setNotificationSettings(settings));
  } catch (e) {
    console.error(e);
  }
}

function* setUserCurrency(action: Effect) {
  try {
    yield call(mainApiProtected.updateProfile, { currency: action.payload });
    yield put(changeUserCurrency({ currency: action.payload }));
  } catch (e) {
    console.error(e);
  }
}

function* watchFetchUserProfile() {
  yield takeLatest(['LOAD_APP', userActions.fetchUser.type], fetchUserProfile);
}

function* watchFetchNotificationSettings() {
  yield takeLatest(['LOAD_APP'], fetchNotificationSettings);
}

function* watchUserCurrency() {
  yield takeLatest(userActions.setCurrency.type, setUserCurrency);
}

export default function* userSaga() {
  yield all([
    fork(watchFetchUserProfile),
    fork(watchFetchNotificationSettings),
    fork(watchUserCurrency),
  ]);
}
