import React from 'react';

// import AddFundsModal from '../Funds/components/AddFundsModal';
import TradeProductsModal from '../Products/components/TradeProductsModal';
import ConfirmTxModal from '../Products/components/ConfirmTxModal';
import OtpModal from '../User/components/OtpModal';
import Google2FAModal from '../User/components/Google2FAModal';
import SubscriptionProductModal from '../Products/components/SubscriptionModal/SubscriptionProductModal';
import SubscriptionKRWModal from '../Products/components/SubscriptionModal/SubscriptionKRWModal';
import ConfirmationModal from '../Products/components/ConfirmationModal';
import DepositModal from '../Funds/components/DepositModal';

const ModalProvider = () => (
  <>
    <DepositModal />
    <TradeProductsModal />
    <ConfirmTxModal />
    <OtpModal />
    <Google2FAModal />
    <SubscriptionProductModal />
    <SubscriptionKRWModal />
    <ConfirmationModal />
  </>
);

export default ModalProvider;
