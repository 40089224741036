// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-modal.TradeProductsModal-module__container--fM_QX {\n  max-width: 410px;\n}\n.ant-modal.TradeProductsModal-module__container--fM_QX .ant-modal-content {\n  background: transparent;\n  border-radius: 20px;\n}\n.ant-modal.TradeProductsModal-module__container--fM_QX .ant-modal-body {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Products/components/TradeProductsModal/TradeProductsModal.module.less"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AADE;EAII,uBAAA;EACA,mBAAA;AAAN;AALE;EASI,UAAA;AADN","sourcesContent":[":global(.ant-modal) {\n  &.container {\n    max-width: 410px;\n\n    :global(.ant-modal-content) {\n      background: transparent;\n      border-radius: 20px;\n    }\n\n    :global(.ant-modal-body) {\n      padding: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TradeProductsModal-module__container--fM_QX"
};
export default ___CSS_LOADER_EXPORT___;
