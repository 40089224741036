import { hotjar } from 'react-hotjar';

const hjid = process.env.REACT_APP_HOTJAR_ID;
const hjsv = 6;

const initializeHotjar = () => {
  if (hjid) {
    hotjar.initialize(Number(hjid), hjsv);
  }
};

export default initializeHotjar;
