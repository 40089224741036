import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { ApplicationState } from '../../redux/store';

export const selectFundsDomain = (state: ApplicationState) =>
  state.funds || initialState;

export const makeSelectFunds = () =>
  createSelector(selectFundsDomain, (state) => state.funds);

export const makeSelectFunds2 = () =>
  createSelector(selectFundsDomain, (state) => Object.values(state.funds));

export const makeSelectTotalFunds = () =>
  createSelector(selectFundsDomain, (state) =>
    Object.values(state.funds).reduce(
      (amount, fund) => amount + fund.amount * fund.price,
      0
    )
  );
export const makeSelectTotalBonusFunds = () =>
  createSelector(selectFundsDomain, (state) =>
    Object.values(state.bonusBalances)
  );

export const makeSelectFundsLoading = () =>
  createSelector(selectFundsDomain, (state) => state.loading);

export const makeSelectUserProfitInCurrency = () =>
  createSelector(selectFundsDomain, (state) => state.profit);
